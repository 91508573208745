const baseURL = ""; //http://localhost:8080

export async function exchange(url, type, body, isMultipart) {
    let request = {
        method: type,
        headers: {
            'Accept': 'application/json',
            'auth-token' : localStorage.getItem('genai-app-jwt')
        }
    }
    if (body != null)
        request.body = JSON.stringify(body);

    if(null != isMultipart && isMultipart) {
         request.headers = {
            'Auth-Token' : localStorage.getItem('genai-app-jwt')
         }
        request.body = body;
    }   
    else {
        if (type == "POST" || type=="PUT"||type=="DELETE")
            request.headers = {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
                'auth-token' : localStorage.getItem('genai-app-jwt')
            }
    }
    return await fetch(baseURL + url, request)
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            return data;
        })
        .catch((error) => { console.log(error); });
}