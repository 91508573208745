import * as React from "react";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { exchange } from "../common/ApiUtils";
import {
  Dialog,
  DialogActions,
  TextField,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Snackbar,
  SnackbarContent,
  InputAdornment,
} from "@mui/material";

export const API_BASE_URL = "/api";
console.log("NODE_ENV:", process.env.NODE_ENV);

const settings = ["My Details", "Change Password", "Logout", "App Version"];

function ResponsiveAppBar(props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [newPasswordError, setNewPasswordError] = React.useState("");
  const [passwordChanged, setPasswordChanged] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarColor, setSnackbarColor] = React.useState("green");

  // Separate showPassword states for each password field
  const [showPasswordOld, setShowPasswordOld] = React.useState(false);
  const [showPasswordNew, setShowPasswordNew] = React.useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
  const [fontWeight, setFontWeight] = React.useState("RAG");
  const appVersion = process.env.REACT_APP_VERSION;
  console.log("process.env.REACT_APP_VERSION", process.env.REACT_APP_VERSION);

  const history = useHistory();
  let username = "";
  let jwttoken = localStorage.getItem("genai-app-jwt");
  let idtoken = localStorage.getItem("genai-app-idtoken");
  const decodedToken = jwtDecode(idtoken, { complete: true });

  if (decodedToken && decodedToken["email"]) {
    username = decodedToken["email"];
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);

    if (event.currentTarget.dataset["key"] == "Logout") {
      localStorage.removeItem("genai-app-idtoken");
      localStorage.removeItem("llm_Response");
      localStorage.removeItem("genai-app-jwt");
      localStorage.removeItem("LlmName");
      localStorage.removeItem("llmid");
      localStorage.removeItem("selectedCloudId");
      localStorage.removeItem("selectedLlmId");
      history.push("/");
    } else if (event.currentTarget.dataset["key"] === "Change Password") {
      setOpenDialog(true);
    } else {
      console.log(event.currentTarget.dataset["key"]);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // Reset form fields and error messages
    setPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setPasswordError("");
    setNewPasswordError("");
    setShowPasswordOld(false); // Reset password visibility state for old password
    setShowPasswordNew(false); // Reset password visibility state for new password
    setShowPasswordConfirm(false); // Reset password visibility state for confirm password
  };

  const handlePasswordChange = () => {
    setUploading(true); // Set uploading to true when upload begins
    // Check if old password and new password are the same
    if (password === newPassword) {
      setPasswordError("Old password and new password cannot be the same");
      return;
    }
    // Validation logic
    if (newPassword !== confirmPassword) {
      setPasswordError("");
      setNewPasswordError("New password and confirm password do not match");
      return;
    }
    if (
      newPassword.length < 8 ||
      !/[0-9]/.test(newPassword) ||
      !/[a-z]/.test(newPassword) ||
      !/[A-Z]/.test(newPassword) ||
      !/[^\s\w]/.test(newPassword)
    ) {
      setPasswordError("");
      setNewPasswordError(
        "Password must be at least 8 characters long and contain at least one number, one lowercase letter, one uppercase letter, and one special character or non-leading, non-trailing space"
      );
      return;
    }
    // Api call
    exchange(`${API_BASE_URL}/changepassword`, "POST", {
      oldPassword: password,
      newPassword: newPassword,
      accessToken: jwttoken,
    })
      .then((response) => {
        console.log(response.data);
        if (
          response.message === "Password changed successfully" ||
          response.status === 200
        ) {
          setPasswordChanged(true); // Set passwordChanged state to true only on success
          setSnackbarOpen(true);
          setSnackbarMessage("Password changed successfully!");
          setSnackbarColor("green");
          handleCloseDialog();
        } else {
          throw new Error(response.data.error); // Throw error for non-200 status codes
        }
      })
      .catch((error) => {
        console.error("Error changing password:", error.message);
        setPasswordError("Failed to change password");
        setUploading(false); // Set uploading to false if there's an error
        setSnackbarOpen(true);
        setSnackbarMessage("Password change failed!"); // Display error message from server
        setSnackbarColor("red");
      })
      .finally(() => {
        setUploading(false); // Set uploading to false after completing the password change process
      });
  };

  const handlePasswordInput = (value, field) => {
    // Validation logic while typing
    if (field === "password") {
      setPassword(value);
      setPasswordError("");
    } else if (field === "newPassword") {
      setNewPassword(value);
      if (
        value.length < 8 ||
        !/[0-9]/.test(value) ||
        !/[a-z]/.test(value) ||
        !/[A-Z]/.test(value) ||
        !/[^\s\w]/.test(value)
      ) {
        setPasswordError("");
        setNewPasswordError(
          "Password must be at least 8 characters long and contain at least one number, one lowercase letter, one uppercase letter, and one special character or non-leading, non-trailing space"
        );
      } else {
        setNewPasswordError("");
      }
    } else if (field === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="90%">
          <Toolbar disableGutters>
            <Avatar
              alt=""
              src="/ustlogo.jpg" // Adjust the path accordingly
              style={{ padding: "5px", margin: "5px" }}
            />
            <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {props.pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => {
                    props.onPageSelect(page, setFontWeight);
                  }}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    fontWeight: page === fontWeight ? "bold" : "normal",
                    // fontWeight: fontWeight,
                    backgroundColor: page === fontWeight ? "#388adb" : "null",
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box
              sx={{ display: "flex", justifyContent: "flex-end", flexGrow: 0 }}
            >
              <Button
                onClick={() => props.onPageSelect("Feedback", setFontWeight)}
                sx={{ color: "white", marginRight: 2 }}
              >
                Feedback
              </Button>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={username.charAt(0).toUpperCase()}
                    src="/static/images/avatar/2.jpg"
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {/* First group */}
                {settings.slice(0, 3).map((setting) => (
                  <MenuItem
                    key={setting}
                    data-key={setting}
                    onClick={handleCloseUserMenu}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}

                {/* Separator line */}
                <Box
                  sx={{
                    borderBottom: "1px solid gray",
                    my: 1, // margin top and bottom
                  }}
                />

                {/* Second group */}
                <MenuItem key="App Version" onClick={handleCloseUserMenu}>
                  <Typography
                    textAlign="center"
                    style={{ "font-size": "small" }}
                  >
                    App Version: {appVersion}
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Password Change Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <TextField
              label="Old Password"
              type={showPasswordOld ? "text" : "password"}
              value={password}
              onChange={(e) => handlePasswordInput(e.target.value, "password")}
              fullWidth
              margin="normal"
              required
              error={!!passwordError}
              helperText={passwordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPasswordOld(!showPasswordOld)}
                      edge="end"
                    >
                      {showPasswordOld ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="New Password"
              type={showPasswordNew ? "text" : "password"}
              value={newPassword}
              onChange={(e) =>
                handlePasswordInput(e.target.value, "newPassword")
              }
              fullWidth
              margin="normal"
              required
              error={!!newPasswordError}
              helperText={newPasswordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPasswordNew(!showPasswordNew)}
                      edge="end"
                    >
                      {showPasswordNew ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Confirm New Password"
              type={showPasswordConfirm ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) =>
                handlePasswordInput(e.target.value, "confirmPassword")
              }
              fullWidth
              margin="normal"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPasswordConfirm(!showPasswordConfirm)
                      }
                      edge="end"
                    >
                      {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={handlePasswordChange}
            autoFocus
            disabled={uploading || passwordChanged}
          >
            {uploading ? <CircularProgress size={24} /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for showing password change success or failure message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Centered position
      >
        <SnackbarContent
          message={snackbarMessage}
          style={{
            backgroundColor: snackbarColor, // Background color based on snackbarColor state
            width: "100%",
          }}
        />
      </Snackbar>
    </>
  );
}

export default ResponsiveAppBar;
