import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { exchange } from "../common/ApiUtils";
import { useState, useEffect, useRef } from "react";
import TablePagination from "@mui/material/TablePagination";
import { API_BASE_URL } from "../App";
import { Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import CircularProgress from "@mui/material/CircularProgress";
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { responsiveProperty } from '@mui/material/styles/cssUtils';

// import jwtDecode from 'jwt-decode';
const drawerWidth = 240;

function ResponsiveDrawer() {

    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false)
    const [selctedbucode, setselectedbucode] = useState();
    const [selectedname, setselectedname] = useState()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = React.useState(false);
    const [spinningUser, setSpinningUser] = useState(false);
    const [buCodeMap, setBUCodeMap] = useState(new Map());
    const [configType, setConfigType] = useState('tenant_config');
    // const [loading,setloading]=useState(false)
    const [formData, setFormData] = React.useState({
        bucode: '',
        name: ''
    });
    const [errors, setErrors] = useState({ bucode: '', name: '' });
    const [isEditMode, setIsEditMode] = useState(false);
    const [businessUnits, setBusinessUnits] = useState([]);
    const [loading, setLoading] = useState(false);
    const hasFetched = useRef(false);
    const [isBucodeDisabled, setIsBucodeDisabled] = useState(false);



    //validation
    const validateForm = () => {
        const newErrors = { bucode: '', name: '' };
        let isValid = true;

        if (!formData.bucode) {
            newErrors.bucode = 'Bucode is required';
            isValid = false;
        }

        if (!formData.name) {
            newErrors.name = 'Name is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //bucode disable for edit
    useEffect(() => {
        // Disable the bucode field if iseditmode is true
        setIsBucodeDisabled(isEditMode);
    }, [isEditMode]);

    useEffect(() => {
        if (!hasFetched.current) {
            // getUser(true);
            fetchConfigData("tenant_config", "", true);
            hasFetched.current = true;
        }
    }, []);

    const fetchConfigData = async (configType, action, isLoader) => {
        if (isLoader)
            setLoading(true)
        try {
            const response = await exchange(
                `${API_BASE_URL}/config/${configType}`,
                "GET"
            );
            const doubleEncodedData = response.body;
            const data = JSON.parse(JSON.parse(doubleEncodedData));

            // Create a map for business unit names to their keys
            const buCodeMap = new Map();
            data.departments.forEach(department => {
                buCodeMap.set(department.name, department.key);
            });

            // Map supported_clouds with departments
            const mappedClouds = data.supported_clouds.map(cloud => {
                const cloudDepartments = cloud.departments && cloud.departments !== '' ? cloud.departments.split(',') : [];
                // Filter departments based on cloud's departments list
                const mappedDepartments = data.departments.filter(department => cloudDepartments.includes(department.key));
                const departmentNames = mappedDepartments.map(department => department.name);
                console.log(departmentNames, "departmentNames");

                return {
                    ...cloud,
                    mappedDepartments: mappedDepartments
                };
            });
            // setBusinessUnits()
            const allDepartmentNames = mappedClouds.flatMap(cloud => cloud.mappedDepartments.map(department => department.name));
            setBusinessUnits(Array.from(new Set(allDepartmentNames)));
            if (action == "add") {
                toast.success("Configuration added successfully")
                setSpinningUser(false)
                handleClose()
            }
            if (action == "update") {
                toast.success("Configuration updated successfully")
                setSpinningUser(false)
                handleClose()
            }
            if (action == "delete") {
                toast.success("Business unit deleted successfully.");
                setSpinningUser(false)
                setShowDeleteConfirmDialog(false)
            }

            setLoading(false)
            // Save the buCodeMap to be used in the component
            setBUCodeMap(buCodeMap);

        } catch (error) {
            console.error("Error fetching config data:", error);
        }
    };

    // Helper function to get BU Code
    const getBUCode = (businessUnit) => {
        return buCodeMap.get(businessUnit) || 'N/A';
    };

    const handleClickOpen = () => {
        setIsEditMode(false)
        setOpen(true);
    };
    const handleEditOpen = (bucode, name) => {
        setFormData({ bucode, name });
        setIsEditMode(true);
        setOpen(true);
        setIsBucodeDisabled(true);

    }


    const handleClose = () => {
        setOpen(false);
        setFormData({ bucode: '', name: '' });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value
        });

        // Clear specific error message based on the field being edited
        if (name === 'bucode') {
            setErrors({
                ...errors,
                bucode: value ? '' : errors.bucode
            });
        } else if (name === 'name') {
            setErrors({
                ...errors,
                name: value ? '' : errors.name
            });
        }
    };

    const handleDeleteClick = (bucode, name) => {
        console.log("User details: ", bucode);
        console.log(name, "Name_bu")
        setselectedbucode(bucode)
        setselectedname(name)
        setShowDeleteConfirmDialog(true);

    };

    const departmentDelete = (callback) => {
        exchange('/api/admin/documents', "GET", null)
            .then((response) => {
                const data_documents_department = response
                    .filter(document => document.department)
                    .map(document => document.department)
                const uniqueDepartments = [...new Set(data_documents_department)];
                console.log(uniqueDepartments)
                if (callback) {
                    callback(uniqueDepartments)
                }

            })
    }
    const containsPartialMatch = (name, departmentValues) => {
        return departmentValues.some(department => name.includes(department));
    };

    const deleteBusinessUnit = (bucodeToDelete, name) => {
        const Token = localStorage.getItem("genai-app-idtoken");
        const loggedInAdminTenant = getTokenTenant(Token);
        setSpinningUser(true)
        departmentDelete((uniqueDepartments) => {
            exchange('/api/admin/users', "GET", null)
                .then((response) => {
                    const data = response.users;
                    console.log("Nonfiltereddata", data)
                    if (Array.isArray(data)) {
                        const filteredUsers = data.filter(user => user['custom:tenant'] === loggedInAdminTenant);
                        const filteredDepartment = data.filter(dep => uniqueDepartments.includes(dep['custom:department']));
                        const departmentValues = filteredDepartment.map(dep => dep['custom:department']);
                        console.log(departmentValues);
                        const usersInBucode = filteredUsers.filter(user => user['custom:department'] === bucodeToDelete);
                        // departmentDelete()
                        console.log("usersInBucode", usersInBucode)
                        if (containsPartialMatch(name, departmentValues)) {
                            toast.error(
                                <div>
                                    Unable to delete <strong>{name}</strong>, untag Documents before deleting the Business unit.
                                </div>,
                                {
                                    style: {
                                        width: '118%',
                                        marginLeft: '-21%'
                                    },
                                    autoClose: false,
                                }
                            );
                            setSpinningUser(false);
                            setShowDeleteConfirmDialog(false);
                            return; // Exit early
                        }

                        if (usersInBucode.length > 0) {
                            // If there are users associated with the bucode, show a toast message
                            toast.error(
                                <div >
                                    Unable to delete <strong>{bucodeToDelete}</strong>, untag users before deleting the Business unit.
                                </div>,
                                {
                                    style: {
                                        width: '118%',
                                        marginLeft: '-21%'
                                    },

                                    autoClose: false,
                                }
                            );
                            setSpinningUser(false)
                            setShowDeleteConfirmDialog(false)

                        } else {
                            // If no users are linked, proceed with the delete operation
                            deleteBucode(bucodeToDelete);
                        }


                    }
                })
        })
    }

    const deleteBucode = (bucode, isLoader) => {
        if (isLoader)
            setLoading(true)
        setSpinningUser(true)
        // Call the delete API
        exchange(`/api/config/${configType}/${bucode}`, "DELETE")
            .then(response => {
                if (response.statusCode == 200) {
                    setLoading(false)
                    fetchConfigData("tenant_config", "delete", false);
                }
                else {
                    toast.error("Failed to delete business unit.");
                    setLoading(false)
                    setSpinningUser(false)

                }

            })
            .catch(error => {
                toast.error("Failed to delete business unit.");
                console.error("Error deleting bucode:", error);
            });
    }

    function getTokenTenant(token) {
        // Parse JWT token to extract the tenant
        if (!token) return null;

        const tokenParts = token.split('.');
        if (tokenParts.length !== 3) return null;

        const payload = JSON.parse(atob(tokenParts[1]));
        return payload['custom:tenant'];
    }


    //creating the Businessunit
    const handleBusinessUnit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            const payload = {
                bucode: formData.bucode,
                name: formData.name,
            };

            setSpinningUser(true)
            if (isEditMode) {
                // Update the configuration
                exchange(`${API_BASE_URL}/config/${configType}`, "PUT", payload)
                    .then((response) => {
                        if (response.statusCode == 200) {
                            fetchConfigData("tenant_config", "update", false);
                        }
                        else {
                            toast.error("Failed to add configuration");
                            setSpinningUser(false)
                            handleClose()
                        }
                    })
            }
            else {
                setSpinningUser(true)
                exchange(`${API_BASE_URL}/config/${configType}`, "POST", payload)
                    .then((response) => {
                        console.log("response", response)
                        if (response.statusCode == 201) {
                            fetchConfigData("tenant_config", "add", false);
                        }
                        else if (response.statusCode == 400) {
                            toast.error("BU code or Name already exists");
                            setSpinningUser(false)
                            handleClose()

                        }
                        else {
                            toast.error("Failed to add configuration");
                            setSpinningUser(false)
                            handleClose()

                        }
                    })
                    .catch((error) => {
                        console.error("There was an error!", error);
                        toast.error("An error occurred");
                    })
            }

        }

    }
    const paginatedData = businessUnits.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    var displayMessages = "";
    displayMessages = (

        <div className='Main_box'>
            <Grid container height="90vh" style={{ overflow: "clip" }}>
                <Grid xs={12}>
                    {/* {selectedView === 'BusinessUnits' && <BusinessUnit />} */}
                    <Dialog
                        open={showDeleteConfirmDialog}
                        // onClose={() => deleteUser()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Confirm Delete"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Do you want to delete <strong> {selctedbucode} ? </strong>

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => deleteBusinessUnit(selctedbucode, selectedname)}
                                color="primary"
                                disabled={spinningUser}
                            >
                                {spinningUser ? <CircularProgress size={24} /> : "YES"}
                            </Button>
                            <Button
                                onClick={() => setShowDeleteConfirmDialog(false)}
                                color="primary"
                                autoFocus
                                disabled={spinningUser}
                            >
                                NO
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Paper
                        style={{
                            height: "100%",
                            maxHeight: "100%",
                            overflow: "auto",
                            margin: "1px 0px 0px 0px",
                            backgroundColor: "#d6d6d6",

                        }}

                        elevation={3}

                    >
                        {/* <button onClick={department}>Clickme</button> */}

                        <Box height="75vh">
                            {loading && <>


                                <Skeleton variant="text" height={100} style={{ marginTop: '20px', height: '75px', marginLeft: '11%', marginRight: '13%' }} />

                            </>}
                            {!loading &&
                                <div style={{ backgroundColor: 'white', marginRight: '24px', marginLeft: '24px', borderRadius: '8px', padding: '15px' }}>
                                    <><Button variant="contained" color="primary" onClick={handleClickOpen} style={{ marginLeft: '75%', marginTop: '25px', width: '17%' }}>
                                        Create Business Unit
                                    </Button>

                                        <Typography variant="h6" style={{ marginTop: '-30px' }}>Business Units </Typography>
                                    </>
                                </div>}
                            <Dialog open={open} onClose={handleClose} >
                                <DialogTitle>{isEditMode ? 'Edit Business Unit' : 'Create Business Unit'}</DialogTitle>
                                <DialogContent>

                                    <TextField
                                        margin="dense"
                                        name="bucode"
                                        label="bucode"
                                        type="text"
                                        fullWidth
                                        value={formData.bucode}
                                        onChange={handleChange}
                                        disabled={isBucodeDisabled}
                                        error={!!errors.bucode}
                                        helperText={errors.bucode}


                                    />
                                    <TextField
                                        margin="dense"
                                        name="name"
                                        label="Name"
                                        type="text"
                                        fullWidth
                                        value={formData.name}
                                        onChange={handleChange}
                                        error={!!errors.name}
                                        helperText={errors.name}

                                    />


                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={handleBusinessUnit} color="primary" disabled={spinningUser} >
                                        {spinningUser ? <CircularProgress size={24} /> : "Submit"}
                                    </Button>

                                </DialogActions>
                            </Dialog>
                            <Box sx={{ display: 'flex', overflow: 'hidden', marginTop: '-80px' }}>
                                <Box
                                    component="main"
                                    sx={{
                                        flexGrow: 1,
                                        // bgcolor: 'background.default',
                                        p: 3,
                                        mt: 8,
                                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                                    }}
                                >
                                    <div>
                                        <>
                                            <Box sx={{ width: '100%' }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    {loading && (
                                                        <Box sx={{ width: '100%', marginLeft: '16%' }}>
                                                            {/* Skeleton for TableRow */}
                                                            {Array.from(new Array(rowsPerPage)).map((_, index) => (
                                                                <TableRow
                                                                    key={index}
                                                                    sx={{ backgroundColor: index % 2 === 0 ? '#d6d6d6' : '#d6d6d6' }}
                                                                >
                                                                    {/* Skeleton for TableCell */}
                                                                    <TableCell>
                                                                        <Skeleton variant="text" width={40} />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Skeleton variant="text" width={200} />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Skeleton variant="text" width={200} />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Skeleton variant="text" width={150} />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Skeleton variant="circular" width={24} height={24} animation="pulse" />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </Box>
                                                    )}
                                                    {!loading &&
                                                        <TableContainer component={Paper} sx={{ marginTop: '-7px' }} >
                                                            <Table aria-label="striped table" style={{ marginTop: '2%' }}>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {/* <TableCell style={{ width: '10%' }}></TableCell> */}
                                                                        {/* <TableCell style={{ width: '20%' }}></TableCell> */}
                                                                        <TableCell style={{ width: '20%' }} >ID</TableCell>

                                                                        <TableCell style={{ width: '30%' }} >BU Code</TableCell>

                                                                        {/* Adjusted width */}
                                                                        {/* <TableCell style={{ width: '25%' }} >Business Unit</TableCell> */}
                                                                        <TableCell style={{ width: '30%' }}>Name</TableCell>
                                                                        <TableCell style={{ width: '30%' }}>Actions</TableCell>

                                                                        <TableCell style={{ width: '20%' }}></TableCell>

                                                                        {/* <TableCell style={{ width: '15%' }}>Actions</TableCell> Adjusted width */}
                                                                        {/* Adjusted width */}
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>

                                                                    {paginatedData.map((business, index) => (
                                                                        <TableRow
                                                                            key={getBUCode(business)} // Use a unique identifier if available
                                                                            sx={{ backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5' }}
                                                                        >
                                                                            <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                                                                            <TableCell>{getBUCode(business)}</TableCell>
                                                                            <TableCell>{business}</TableCell>
                                                                            <TableCell>
                                                                                <button
                                                                                    style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                                                                                    onClick={() => handleEditOpen(getBUCode(business), business)}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faPenToSquare} style={{ marginBottom: '4px', height: '15px' }} />
                                                                                </button>
                                                                                <button
                                                                                    onClick={() => handleDeleteClick(getBUCode(business), business)}
                                                                                    style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                                                                                >
                                                                                    <DeleteIcon />
                                                                                </button>
                                                                            </TableCell>
                                                                            <TableCell></TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 25]}
                                                                component="div"
                                                                count={businessUnits.length}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                            />
                                                        </TableContainer>}
                                                </Box>
                                            </Box>
                                        </>
                                    </div>
                                </Box>
                            </Box>

                        </Box>

                    </Paper>

                </Grid>

            </Grid>
        </div >
    );

    // const drawer = (
    //   <div>
    //     {/* <button onClick={token()}> clickme</button> */}


    //     <Grid>
    //       {['Roles', 'Users', 'Business Units'].map((text) => (
    //         <ListItem key={text} disablePadding>
    //           <ListItemButton>
    //             <ListItemIcon>
    //               {text === 'Roles' && <FontAwesomeIcon icon={faUserGear} style={{ marginLeft: '4px' }} />}
    //               {text === 'Users' && <SupervisorAccountIcon />}
    //               {text === 'Business Units' && <BusinessIcon />}
    //             </ListItemIcon>
    //             <ListItemText primary={text} />
    //           </ListItemButton>
    //         </ListItem>
    //       ))}
    //     </Grid>
    //   </div>
    // );

    // const container = window !== undefined ? () => window().document.body : undefined;
    return (
        // <div className='main' style={{ border: '1px' }}>
        <>
            {displayMessages}
            <ToastContainer position="bottom-right" />
        </>
        // {/* </div > */}


    );
}

ResponsiveDrawer.propTypes = {
    window: PropTypes.func,
};


export default ResponsiveDrawer;
