import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  Typography,
  Snackbar,
  CircularProgress,
  Alert,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Import close icon
import { exchange } from "../common/ApiUtils";
export const API_BASE_URL = "/api";

function FeedbackDialog({ open, onClose }) {
  const [feedbackType, setFeedbackType] = useState("Feature Request");
  const [description, setDescription] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const handleSubmit = async () => {
    if (description.trim().length < 10) {
      setAlert({
        open: true,
        severity: "error",
        message: "Description must be at least 10 characters long.",
      });
      return;
    }

    setIsLoading(true);
    const feedbackData = {
      feedbackType,
      description,
    };

    const result = await sendFeedbackToApi(feedbackData);

    if (result.success) {
      setAlert({
        open: true,
        severity: "success",
        message: `Feedback submitted successfully! Your Request ID is ${result.request_id}.`,
      });
      // Reset form
      setFeedbackType("Feature Request");
      setDescription("");
      onClose();
    } else {
      setAlert({
        open: true,
        severity: "error",
        message: "Error submitting feedback. Please try again.",
      });
    }

    setIsLoading(false);
  };

  const sendFeedbackToApi = async (feedbackData) => {
    console.log("Sending feedback with data:", feedbackData);

    const reqstBdy = {
      headers: {
        "Content-Type": "application/json",
      },
      feedbackType: feedbackData.feedbackType,
      description: feedbackData.description,
    };

    try {
      const data = await exchange(`${API_BASE_URL}/feedback`, "POST", reqstBdy);

      if (!data) {
        console.error("Error: No data returned");
        return { success: false };
      } else if (data?.statusCode === 200) {
        console.log("Feedback submitted successfully:", data);
        // Return the request_id from the response body
        return { success: true, request_id: data.body.request_id };
      } else {
        console.error("Submission failed:", data);
        return { success: false };
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      return { success: false };
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setAlert({ ...alert, open: false });
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Provide Feedback</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Please select the type of feedback and provide a description.
          </Typography>
          <Select
            value={feedbackType}
            onChange={(e) => setFeedbackType(e.target.value)}
            fullWidth
            variant="outlined"
            margin="dense"
            disabled={isLoading} // Disable the form while loading
          >
            <MenuItem value="Feature">Feature Request</MenuItem>
            <MenuItem value="Issue">Report an Issue</MenuItem>
          </Select>
          <TextField
            label="Description"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setIsTyping(true);
            }}
            onBlur={() => setIsTyping(false)}
            margin="normal"
            disabled={isLoading} // Disable the form while loading
          />
          {isTyping && description.trim().length < 10 && (
            <Typography color="error" variant="body2">
              Description must be at least 25 characters long.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" disabled={isLoading}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={description.trim().length < 25 || isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Submit Feedback"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar with Alert */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={alert.open}
        onClose={handleCloseSnackbar}
        autoHideDuration={null} // Disable auto-hide for manual closing
      >
        <Alert
          severity={alert.severity}
          variant="filled"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default FeedbackDialog;
