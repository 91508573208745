import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Paper,
  Box,
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  DialogContentText,
  Checkbox,
  ListItemText,
  Card,
  CardActionArea,
  Typography,
  CardActions,
  CardContent,
  Stack,
  Grid,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import {
  Add as AddIcon,
  Sync as SyncIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  ErrorOutline as ErrorOutlineIcon,
} from "@mui/icons-material";
import { TextField, InputLabel } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import awsIcon from "../images/aws-icon.png"; // Adjust the path to your AWS icon
import azureIcon from "../images/azure-icon.png"; // Adjust the path to your Azure icon
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

function EditToolbar({ handleClickOpen }) {
  return null;
}

const TableComponent = ({
  columns,
  docNames,
  listLoading,
  alert,
  handleClickOpen,
  handleSync,
  handleViewClick,
  handleDeleteClick,
  handleFileChange,
  uploadDocuments,
  disabledButton,
  open,
  handleClose,
  uploading,
  showSync,
  handleCloseSync,
  syncing,
  syncingSucceeded,
  syncStatistics,
  syncingFailed,
  showDeleteConfirmDialog,
  confirmDeleteClose,
  selectedRow,
  isDeleting,
  userRole,
  selectedDepartments,
  handleDepartmentChange,
  departments,
  cloudProvider,
  setCloudProvider, // Ensure this is destructured correctly
  cloudProviders,
}) => {
  const AutocompleteWidth = 350;
  const handleCloudProviderChange = (event, newCloudProvider) => {
    if (newCloudProvider !== null) {
      setCloudProvider(newCloudProvider);
    }
  };

  // Map cloud providers to their icons
  const cloudProviderIcons = {
    aws: awsIcon,
    azure: azureIcon,
    // Add more icons as needed
  };
   
  const formatTime = (time) => {
   
    // Convert to a Date object if it's not already one
    const date = new Date(time).toLocaleString();
    return date
 
    // Check if the date is valid
   
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "#d6d6d6",
          height: "90vh",
          width: "100%",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "20px",
          overflowY: "hidden", // Prevents Paper from scrolling
        }}
      >
        <Box
          sx={{
            width: "70%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Card sx={{ mb: "5px", paddingTop: "0px" }} variant="outlined">
            <CardContent
              sx={{ maxHeight: "calc(90vh - 100px)", overflowY: "auto" }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                {(userRole === "admin" || userRole === "superadmin") && (
                  <Grid item xs={10}>
                    <Box textAlign="center">
                      <Typography gutterBottom variant="h5" component="div">
                        Documents ({cloudProvider.toUpperCase()})
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Upload & Sync documents to start Chat with them.
                      </Typography>
                    </Box>
                  </Grid>
                )}
                <Grid item>
                  <ToggleButtonGroup
                    value={cloudProvider}
                    exclusive
                    onChange={handleCloudProviderChange}
                    aria-label="cloud provider"
                  >
                    {cloudProviders.map((provider) => (
                      <ToggleButton
                        key={provider.cloud}
                        value={provider.cloud}
                        aria-label={provider.cloud}
                        sx={{ marginRight: 1 }}
                      >
                        <img
                          src={cloudProviderIcons[provider.cloud]}
                          alt={provider.cloud}
                          width={25}
                          height={25}
                        />
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              {(userRole === "admin" || userRole === "superadmin") && (
                <Grid
                  container
                  justifyContent="flex-end"
                  spacing={2}
                  style={{ marginBottom: "20px" }}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={handleClickOpen}
                    >
                      Upload Document
                    </Button>
                  </Grid>
                  {userRole === "superadmin" && (
                    <Grid item>
                      <Button
                        variant="contained"
                        startIcon={<SyncIcon />}
                        onClick={handleSync}
                      >
                        Sync data source
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )}
            </CardActions>
          </Card>

          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={alert.open}
            onClose={handleClose}
            autoHideDuration={2000}
          >
            <Alert
              severity={alert.severity}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {alert.message}
            </Alert>
          </Snackbar>
          {listLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "auto",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <DataGrid
              sx={{ backgroundColor: "white" }}
              initialState={{
                pagination: { paginationModel: { pageSize: 10, page: 0 } },
              }}
              pageSizeOptions={[5, 10, 25]}
              rows={docNames.map((doc) => ({
                id: doc.id,
                name: doc.name,
                size:
                  Math.round(doc.size / 1024) > 0
                    ? (doc.size / 1024).toFixed(1) + " KB"
                    : doc.size.toFixed(1) + " B",
                created_time:(formatTime(doc.created_time)),
                modified_time:(formatTime(doc.modified_time)),
              }))}
              columns={columns}
              components={{ Toolbar: (props) => <EditToolbar {...props} /> }}
              componentsProps={{ toolbar: { handleClickOpen } }}
            />
          )}
          {(userRole === "admin" || userRole === "superadmin") && (
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Upload a Document"}
              </DialogTitle>
              <DialogContent>
                <Stack direction="row" spacing={7} sx={{ mb: 2 }}>
                  <InputLabel id="doc-label" htmlFor="document">
                    Document
                  </InputLabel>
                  <span>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      accept=".txt, .docx, .pdf, .csv"
                    />{" "}
                  </span>
                </Stack>
                {cloudProviders.map(
                  (provider) =>
                    userRole === "superadmin" &&
                    cloudProvider === provider.cloud &&
                    provider.filter_by_department && (
                      <Stack direction="row" spacing={5} key={provider.cloud}>
                        <InputLabel
                          id="departments-label"
                          htmlFor="departments"
                        >
                          Business Units
                        </InputLabel>

                        <Autocomplete
                          value={selectedDepartments}
                          onChange={handleDepartmentChange}
                          options={departments ? departments : []}
                          getOptionLabel={(option) => option.key}
                          isOptionEqualToValue={(option, value) => option.key === value.key}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Choose BUs"
                              placeholder="Choose BUs"
                              style={{ width: AutocompleteWidth }}
                            />
                          )}
                          multiple
                          disableCloseOnSelect
                          renderOption={(props, option, { selected }) => {
                            const { key, ...otherProps } = props;
                            return (
                              <li key={key} {...otherProps}>
                                <Checkbox
                                  icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                  }
                                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                                  checked={selected}
                                />
                                <ListItemText primary={option.name} />
                              </li>
                            )
                          }}
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => {
                              const { key, ...otherProps } = getTagProps({ index });
                              return (
                                <Chip
                                  label={option.name}
                                  key={key}
                                  {...otherProps}
                                />
                              )
                            })
                          }
                        />
                      </Stack>
                    )
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="primary"
                  disabled={uploading}
                >
                  Cancel
                </Button>
                <Button
                  onClick={uploadDocuments}
                  color="primary"
                  disabled={uploading || disabledButton}
                >
                  {uploading ? <CircularProgress size={24} /> : "Upload"}
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {userRole === "superadmin" && (
            <Dialog
              open={showSync}
              onClose={handleCloseSync}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Sync Data Source"}
              </DialogTitle>
              {syncing && (
                <DialogContent>
                  <DialogContentText>Syncing in progress...</DialogContentText>
                  <CircularProgress />
                </DialogContent>
              )}
              {!syncing && syncingSucceeded && cloudProvider == "aws" && (
                <DialogContent>
                  <CheckCircleOutlineIcon color="success" />
                  <DialogContentText>Syncing succeeded.</DialogContentText>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Documents scanned</TableCell>
                          <TableCell>
                            {syncStatistics.numberOfDocumentsScanned}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Documents deleted</TableCell>
                          <TableCell>
                            {syncStatistics.numberOfDocumentsDeleted}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Documents failed</TableCell>
                          <TableCell>
                            {syncStatistics.numberOfDocumentsFailed}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Metadata documents scanned</TableCell>
                          <TableCell>
                            {syncStatistics.numberOfMetadataDocumentsScanned}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Metadata documents modified</TableCell>
                          <TableCell>
                            {syncStatistics.numberOfMetadataDocumentsModified}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DialogContent>
              )}
              {!syncing && syncingSucceeded && cloudProvider == "azure" && (
                <DialogContent>
                  <CheckCircleOutlineIcon color="success" />
                  <DialogContentText>Syncing succeeded.</DialogContentText>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Documents processed</TableCell>
                          <TableCell>
                            {syncStatistics.numberOfDocumentsProcessed}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Documents failed</TableCell>
                          <TableCell>
                            {syncStatistics.numberOfDocumentsFailed}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DialogContent>
              )}
              {!syncing && syncingFailed && (
                <DialogContent style={{ textAlign: "center" }}>
                  <ErrorOutlineIcon color="error" />
                  <DialogContentText>
                    Syncing could not be completed.
                  </DialogContentText>
                </DialogContent>
              )}
              <DialogActions>
                <Button onClick={handleCloseSync} disabled={syncing}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {(userRole === "admin" || userRole === "superadmin") && (
            <Dialog
              open={showDeleteConfirmDialog}
              onClose={confirmDeleteClose(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Confirm Delete"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Do you want to delete '
                  {selectedRow != null ? selectedRow.name : ""}'?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={confirmDeleteClose(true)}
                  color="primary"
                  disabled={isDeleting}
                >
                  {isDeleting ? <CircularProgress size={24} /> : "YES"}
                </Button>
                <Button
                  onClick={confirmDeleteClose(false)}
                  color="primary"
                  autoFocus
                  disabled={isDeleting}
                >
                  NO
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default TableComponent;
