import React, { useState, useEffect } from "react";
import { exchange } from "../common/ApiUtils";
import { API_BASE_URL } from "../App";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import { GridActionsCellItem } from "@mui/x-data-grid";
import TableComponent from "../documents/TableComponent";

export default function AdminFileUpload({ userRole }) {
  const [open, setOpen] = React.useState(false);
  const [listLoading, setListLoading] = React.useState(false);
  const [isDeleting, setDeleting] = React.useState(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] =
    React.useState(false);
  const [isDownloading, setDownloading] = React.useState(false);

  const [alert, setAlert] = React.useState({
    open: false,
    severity: "success",
    message: "",
  });
  const [uploading, setUploading] = React.useState(false);
  const [disabledButton, setDisabledButton] = React.useState(true);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [fileName, setFileName] = React.useState("");
  const [docNames, setDocNames] = React.useState([]);

  const [showSync, setShowSync] = React.useState(null);
  const [syncing, setSyncing] = React.useState(null);
  const [syncingSucceeded, setSyncingSucceeded] = React.useState(null);
  const [syncingFailed, setSyncingFailed] = React.useState(null);
  const [syncStatistics, setSyncStatistics] = React.useState({});
  const [departments, setDepartments] = React.useState(null);
  const [selectedDepartments, setSelectedDepartments] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [cloudProvider, setCloudProvider] = useState("aws");
  const [cloudProviders, setCloudProviders] = useState([]);

  React.useEffect(() => {
    fetchConfigData("tenant_config"); // Replace 'tenant_config' with your desired config type
    fetchData();
    fetchDepartments();
  }, [cloudProvider]);

  const fetchConfigData = async (configType) => {
    try {
      const response = await exchange(
        `${API_BASE_URL}/config/${configType}`,
        "GET"
      );
      const doubleEncodedData = response.body;
      const data = JSON.parse(JSON.parse(doubleEncodedData));
      const supportedClouds = data.supported_clouds || [];
      setCloudProviders(supportedClouds);
    } catch (error) {
      console.error("Error fetching config data:", error);
    }
  };

  const fetchData = () => {
    setListLoading(true);
    let url;

    if (cloudProvider === "aws") {
      url = `${API_BASE_URL}/admin/documents`;
    } else if (cloudProvider === "azure") {
      url = `${API_BASE_URL}/admin/az/documents`;
    } else {
      console.error("Unknown cloud provider:", cloudProvider);
      setListLoading(false);
      return;
    }

    exchange(url, "GET")
      .then((data) => {
        setDocNames(data);
        setListLoading(false);
      })
      .catch((error) => {
        setDocNames([]);
        setListLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const handleClose = () => {
    setAlert({ open: false, severity: "success", message: "" });
    setOpen(false);
    setSelectedDepartments([]); // Reset selected departments to an empty array
  };

  const handleClickOpen = () => {
    setOpen(true);
    setDisabledButton(true);
    setUploading(false);
    setSelectedFile(null); // Reset selectedFile to null when dialog is opened
  };

  const handleFileChange = (event) => {
    const file = event.target.files;
    setSelectedFile(file);
    const fileNamesArray = Array.from(file).map((file) => file.name);
    setFileName(fileNamesArray[0].replace(/ /g, "_"));
    if (userRole === "superadmin") {
      setDisabledButton(!(file.length > 0 && selectedDepartments.length > 0));
    } else {
      setDisabledButton(false);
    }
  };

  const handleDeleteClick = (row) => () => {
    setShowDeleteConfirmDialog(true);
    setSelectedRow(row);
  };

  const confirmDeleteClose = (isDelete) => () => {
    if (!isDelete) {
      setDeleting(false);
      setShowDeleteConfirmDialog(false);
      return;
    } else {
      setDeleting(true);
      exchange(
        `${API_BASE_URL}/admin/documents/${selectedRow.id}`,
        "DELETE"
      ).then((dataResp) => {
        setDeleting(false);
        setShowDeleteConfirmDialog(false);
        if (dataResp.statusCode === 204) {
          setAlert({
            open: true,
            severity: "success",
            message: selectedRow.name + " deleted successfully!",
          });
          docNames.splice(selectedRow.id, 1);
          setDocNames((prevDocNames) =>
            prevDocNames.filter((doc) => doc.id !== selectedRow.id)
          );
        } else {
          setAlert({
            open: true,
            severity: "error",
            message: "Failed to delete file : " + selectedRow.name,
          });
        }
      });
    }
  };

  const handleViewClick = (row) => () => {
    const { id, name } = row;
    let url = `${API_BASE_URL}/admin/az/documents/` + name;
    if (cloudProvider === "aws") {
      url = `${API_BASE_URL}/admin/documents/${id}`;
    }
    exchange(url, "GET")
      .then((dataResp) => {
        fetch(dataResp)
          .then((response) => {
            if (response.status === 200) {
              return response.blob();
            } else {
              throw new Error("Failed to fetch file");
            }
          })
          .then((content) => {
            var url = window.URL.createObjectURL(content);
            var a = document.createElement("a");
            a.href = url;
            a.download = name;
            document.body.appendChild(a);
            a.click();
            a.remove();
          })
          .catch((error) => {
            console.error("Error fetching file:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const uploadDocuments = () => {
    let url = `${API_BASE_URL}/admin/az/documents/` + fileName;
    let method = "GET";
    if (cloudProvider === "aws") {
      url = `${API_BASE_URL}/admin/documents/` + fileName;
      method = "POST";
    }
    if (selectedFile) {
      setUploading(true); // Set uploading to true when upload begins
      exchange(url, method)
        .then((responseData) => {
          setUploading(true);

          // If "All" is selected, include all department names
          let departmentsToSend = selectedDepartments.some(dep => dep.key === "All")
            ? departments.filter(dep => dep.key !== "All")
            : selectedDepartments;
          departmentsToSend = departmentsToSend.map(dep => dep.key);

          if (cloudProvider === "aws") {
            const post_url = responseData["url"];
            const dataFields = responseData["fields"];
            const preSignedFmData = new FormData();
            for (const key in dataFields) {
              preSignedFmData.append(key, dataFields[key]);
            }
            preSignedFmData.append("file", selectedFile[0]);
            fetch(post_url, {
              method: "POST",
              body: preSignedFmData,
            })
            .then((response) => {
              if (response.status === 204) {
                setUploading(true);
                let reqstBdy = {
                  filename: fileName,
                  departments: departmentsToSend,
                  filesize: selectedFile[0].size,
                };
                exchange(`${API_BASE_URL}/admin/documents`, "POST", reqstBdy)
                  .then((response) => {
                    if (response.statusCode === 204) {
                      fetchData();
                      console.log("File uploaded successfully");
                      const parsedResponse = JSON.parse(response.body);
                      setAlert({
                        open: true,
                        severity: "success",
                        message: "File uploaded successfully!",
                      });
                      const uploadedDocument = {
                        id: parsedResponse[0].id,
                        name: parsedResponse[0].name,
                        size: selectedFile[0].size,
                        created_time: new Date(
                          parsedResponse[0].created_time
                        ).toLocaleString(),
                        modified_time: new Date(
                          parsedResponse[0].modified_time
                        ).toLocaleString(),
                      };
                      setDocNames((prevDocNames) => [
                        ...prevDocNames,
                        uploadedDocument,
                      ]);
                    } else {
                      console.log("File uploaded failed");
                      setAlert({
                        open: true,
                        severity: "error",
                        message: "File upload failed!",
                      });
                    }
                  })
                  .catch((error) => {
                    console.error("Server error:", error.message);
                    setAlert({
                      open: true,
                      severity: "error",
                      message: "File upload failed!",
                    });
                  });
              } else {
                console.log("File uploaded failed");
                setAlert({
                  open: true,
                  severity: "error",
                  message: "File upload failed!",
                });
              }
            })
            .catch((error) => {
              console.error("Error uploading file to S3:", error);
              setAlert({
                open: true,
                severity: "error",
                message: "File upload failed!",
              });
            });
          } else {
            setUploading(true);
            fetch(responseData, {
              method: "PUT",
              headers: {
                "x-ms-blob-type": "BlockBlob", // Required header for Azure Blob Storage
                "Content-Type": selectedFile[0].type, // Set the content type to the file's MIME type
              },
              body: selectedFile[0],
            })
            .then((response) => {
              if (response.ok) {
                setUploading(true);
                let reqstBdy = {
                  filename: fileName,
                  departments: departmentsToSend,
                  filesize: selectedFile[0].size,
                };
                exchange(
                  `${API_BASE_URL}/admin/az/documents`,
                  "POST",
                  reqstBdy
                )
                  .then((response) => {
                    if (response.statusCode === 204) {
                      fetchData();
                      console.log("File uploaded successfully");
                      const parsedResponse = JSON.parse(response.body);
                      setAlert({
                        open: true,
                        severity: "success",
                        message: "File uploaded successfully!",
                      });
                      const uploadedDocument = {
                        id: parsedResponse[0].id,
                        name: parsedResponse[0].name,
                        size: selectedFile[0].size,
                        created_time: new Date(
                          parsedResponse[0].created_time
                        ).toLocaleString(),
                        modified_time: new Date(
                          parsedResponse[0].modified_time
                        ).toLocaleString(),
                      };
                      setDocNames((prevDocNames) => [
                        ...prevDocNames,
                        uploadedDocument,
                      ]);
                    } else {
                      console.log("File uploaded failed");
                      setAlert({
                        open: true,
                        severity: "error",
                        message: "File upload failed!",
                      });
                    }
                  })
                  .catch((error) => {
                    console.error("Server error:", error.message);
                    setAlert({
                      open: true,
                      severity: "error",
                      message: "File upload failed!",
                    });
                  });
              } else {
                console.log("File uploaded failed");
                setAlert({
                  open: true,
                  severity: "error",
                  message: "File upload failed!",
                });
              }
            })
            .catch((error) => {
              console.error("Error uploading file to Blob:", error);
              setAlert({
                open: true,
                severity: "error",
                message: "File upload failed!",
              });
            });
          }
        })
        .catch((error) => {
          console.error("Server error:", error.message);
          setAlert({
            open: true,
            severity: "error",
            message: "File upload failed!",
          });
        })
        .finally(() => {
          setUploading(false);
        });
    } else {
      console.log("No file selected.");
    }
  };

  const handleSync = () => {
    setShowSync(true);
    setSyncing(true);
    let reqstBdy = {
      cloud: cloudProvider,
    };
    exchange(`${API_BASE_URL}/admin/knowledgebase/sync`, "POST", reqstBdy)
      .then((response) => {
        if (response.statusCode == 200) {
          initiateSyncStatusCheck(response.request_id);
        } else {
          setSyncingFailed(true);
        }
      })
      .catch((error) => {
        setSyncingFailed(true);
        console.error(error.message);
      });
  };

  const handleCloseSync = () => {
    setShowSync(false);
    setSyncing(false);
    setSyncingSucceeded(false);
    setSyncingFailed(false);
  };

  const fetchDepartments = () => {
    exchange(`${API_BASE_URL}/admin/departments/${cloudProvider}`, "GET")
      .then((data) => {
        let departmentList = [{ key: "All", name: "All" }]; // Initialize with "All" option
        departmentList = [...departmentList, ...data];
        setDepartments(departmentList); // Update departments state
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  };

  const handleDepartmentChange = (event, value) => {
    const selectedValues = value || []; // Handle case where value is undefined
    setSelectedDepartments(selectedValues);
    // If "All" is selected, disable all other selections
    if (selectedValues.some((val) => val.key === "All")) {
      setSelectedDepartments([{ key: "All", name: "All" }]);
    } else {
      setSelectedDepartments(selectedValues.filter((val) => val.key !== "All"));
    }
    // Enable upload button if file and at least one department is selected
    if (selectedFile && selectedValues.length > 0) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedDepartments(departments);
    } else {
      setSelectedDepartments([]);
    }
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        width: 250,
      },
    },
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "name", headerName: "Name", flex: 2 },
    { field: "size", headerName: "Size", flex: 1 },
    { field: "created_time", headerName: "Created Time", flex: 1 },
    { field: "modified_time", headerName: "Modified Time", flex: 1 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<DownloadIcon />}
            label="View"
            onClick={handleViewClick(row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(row)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const initiateSyncStatusCheck = (request_id) => {
    let attemptCount = 0;
    const maxRetries = 5;
    const intervalTime = 30000; // 30 sec in milliseconds

    const getSyncStatus = async () => {
      if (attemptCount < maxRetries) {
        setSyncing(true);
        attemptCount++;
        try {
          exchange(
            `${API_BASE_URL}/admin/knowledgebase/sync/` + request_id,
            "GET"
          ).then((response) => {
            if (response.status == "IN-PROGRESS") {
              setSyncing(true);
              setTimeout(getSyncStatus, intervalTime);
            } else {
              if (response.status == "COMPLETE") {
                setSyncingSucceeded(true);
                setSyncStatistics(response.statistics);
              } else {
                setSyncingFailed(true);
              }
              setSyncing(false);
              return;
            }
          });
        } catch (error) {
          if (attemptCount >= maxRetries) {
            setSyncingFailed(true);
          } else {
            setTimeout(getSyncStatus, intervalTime); // Retry after interval
          }
        }
      } else {
        setSyncing(false);
        setSyncingFailed(true);
        return;
      }
    };
    getSyncStatus(); // Start the first child API call
  };

  return (
    <TableComponent
      columns={columns}
      docNames={docNames}
      listLoading={listLoading}
      alert={alert}
      handleClickOpen={handleClickOpen}
      handleSync={handleSync}
      handleViewClick={handleViewClick}
      handleDeleteClick={handleDeleteClick}
      handleFileChange={handleFileChange}
      uploadDocuments={uploadDocuments}
      disabledButton={disabledButton}
      open={open}
      handleClose={handleClose}
      uploading={uploading}
      showSync={showSync}
      handleCloseSync={handleCloseSync}
      syncing={syncing}
      syncingSucceeded={syncingSucceeded}
      syncStatistics={syncStatistics}
      syncingFailed={syncingFailed}
      showDeleteConfirmDialog={showDeleteConfirmDialog}
      confirmDeleteClose={confirmDeleteClose}
      selectedRow={selectedRow}
      isDeleting={isDeleting}
      userRole={userRole}
      selectedDepartments={selectedDepartments}
      handleDepartmentChange={handleDepartmentChange}
      departments={departments}
      cloudProvider={cloudProvider}
      setCloudProvider={setCloudProvider}
      cloudProviders={cloudProviders}
    />
  );
}
