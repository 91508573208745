import { useState, useEffect } from "react";
import MyChatMessages from "../Messages/MyChatMessages";
import UserDefaultPage from "./UserDefaultPage";
import { Box, Paper } from "@mui/material";
import ResponsiveAppBar from "../Appbar/Appbar";
import UserFileList from "./UserFileLists";
import CodeConverter from "../CodeConverter/CodeConverter";
import { useLocation } from "react-router-dom";
import NonRagMessages from "../Messages/NonRagComponent";
import AgentChatMessages from "../Messages/AgentChatMessages";
import FeedbackDialog from "../Feedback/FeedbackDialog"; // New import

function UserMainPage({ userRole }) {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [pageName, setPageName] = useState("MyChatMessages");
  const [appliedLlm, setAppliedLlm] = useState("");
  const location = useLocation();
  const { devGrpUser } = location.state || {};
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  useEffect(() => {
    const storedLlm = localStorage.getItem("LlmName");
    if (storedLlm) {
      setAppliedLlm(storedLlm);
    }
  }, []);
  let mainBodyPage = "HomePage";

  const showPage = (page, setFontWeight) => {
    console.log("Page Name is ::" + page + "::");
    if (page === "About") {
      setPageName("HomePage");
      setFontWeight("About");
      setAppliedLlm(appliedLlm);
    } else if (page === "Documents") {
      setPageName("Documents");
      setFontWeight("Documents");
      setAppliedLlm(appliedLlm);
    } else if (page === "RAG") {
      setPageName("MyChatMessages");
      setFontWeight("RAG");
      setAppliedLlm(appliedLlm);
    } else if (page === "Code Converter" && devGrpUser) {
      setPageName("CodeConverter");
      setFontWeight("Code Converter");
      setAppliedLlm(appliedLlm);
    } else if (page === "GenAI") {
      setPageName("GenAI");
      setFontWeight("GenAI");
    } else if (page === "Intelligent Assistant") {
      setPageName("Intelligent Assistant");
      setFontWeight("Intelligent Assistant");
    } else if (page === "Feedback") {
      setIsFeedbackOpen(true); // Open feedback dialog instead of changing page
    }
  };

  if (pageName === "HomePage") {
    mainBodyPage = (
      <UserDefaultPage appliedLlm={appliedLlm} setAppliedLlm={setAppliedLlm} />
    );
  } else if (pageName === "MyChatMessages") {
    mainBodyPage = (
      <MyChatMessages appliedLlm={appliedLlm} setAppliedLlm={setAppliedLlm} />
    );
  } else if (pageName === "Documents") {
    mainBodyPage = (
      <UserFileList
        userRole={userRole}
        appliedLlm={appliedLlm}
        setAppliedLlm={setAppliedLlm}
      />
    );
  } else if (pageName === "CodeConverter" && devGrpUser) {
    mainBodyPage = (
      <CodeConverter appliedLlm={appliedLlm} setAppliedLlm={setAppliedLlm} />
    );
  } else if (pageName === "GenAI") {
    mainBodyPage = (
      <NonRagMessages appliedLlm={appliedLlm} setAppliedLlm={setAppliedLlm} />
    );
  } else if (pageName === "Intelligent Assistant") {
    mainBodyPage = (
      <AgentChatMessages
        appliedLlm={appliedLlm}
        setAppliedLlm={setAppliedLlm}
      />
    );
  }
  let pageMenus = [
    "RAG",
    "Documents",
    "GenAI",
    "Intelligent Assistant",
    "About",
  ];
  if (devGrpUser) {
    pageMenus = [
      "RAG",
      "Documents",
      "GenAI",
      "Code Converter",
      "Intelligent Assistant",
      "About",
    ];
  }

  if (isLoggedIn) {
    return (
      <Box height="100vh">
        <Paper elevation={1}>
          <ResponsiveAppBar
            onPageSelect={showPage}
            pages={pageMenus}
            selectedPage={pageName}
          />
        </Paper>
        {mainBodyPage}
        <FeedbackDialog
          open={isFeedbackOpen}
          onClose={() => setIsFeedbackOpen(false)}
        />
      </Box>
    );
  } else {
    return <div></div>;
  }
}

export default UserMainPage;
