import * as React from 'react';
import { Box, Card, CardContent, CardMedia, Container, Grid, Paper, Typography, Dialog, IconButton, Toolbar, AppBar, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import LambdaIcon from '../images/LambdaIcon.png';
import DynamoDBIcon from '../images/DynamoDBIcon.png';
import BedRockIcon from '../images/BedRockIcon.png';
import KnowledgeBaseIcon from '../images/KnowledgeBaseIcon.png';
import OpenSearchIcon from '../images/OpenSearchServiceIcon.png';
import LexIcon from '../images/LexIcon.png';
import ArchitectureImg from '../images/genaiarchitecture.png'
export default function UserDefaultPage(props) {
    const styles = {
        media: {
            height: '50vh', // Initial height, adjust as needed
            maxHeight: '80vh', // Maximum height, adjust as needed
            objectFit: 'cover',
        },
        card: {
            position: 'relative',
        },
        overlay: {
            position: 'absolute',
            bottom: '30px',
            right: '20px',
            color: 'white',
            backgroundColor: 'transparent',
            opacity: 0.9,
            textAlign: 'left',
            width: '35%'
        },
        Icon: {
            width: '22px', // Adjust width as needed
            height: 'auto', // Maintain aspect ratio
            margin: '0 8px' // Add margin for space between images
        },
        iconContainer: {
            marginTop: '20px', // Add top margin to the icon container
            justifyContent: 'space-evenly',
        },
    };

    const [showModal1, setShowModal1] = React.useState(false);
    const [showModal2, setShowModal2] = React.useState(false);

    const openModal1 = () => {
        setShowModal1(true);
    };
    const closeModal1 = () => {
        setShowModal1(false);
    };
    const openModal2 = () => {
        setShowModal2(true);
    };
    const closeModal2 = () => {
        setShowModal2(false);
    };

    return (
        <Box>
            <Card style={styles.card}>
                <CardMedia image={require('../images/ai-banner.jpg')} style={styles.media} />
                <Container style={styles.overlay} >
                    <h1> AWS GenAI initiative using AWS Bedrock</h1>
                    <br /><br />
                    <h3>Use Case</h3>
                    <span style={{ fontSize: "14px" }}>The Quarterly financial reports filed with the securities and exchange commission (Edgar - https://www.sec.gov/edgar) is downloaded and fed into Bedrock which inturn uses this data for fine tuning and stores in a Vector DB.  When the end user queries using a prompt, the right information is retrieved by Bedrock from various sources including the vector DB</span>
                    <div style={styles.iconContainer}>
                        <Tooltip title="Bedrock">
                            <a><img src={BedRockIcon} alt="BedRock" style={styles.Icon} /></a>
                        </Tooltip>
                        <Tooltip title="Lambda">
                            <a><img src={LambdaIcon} alt="Lambda" style={styles.Icon} /></a>
                        </Tooltip>
                        <Tooltip title="DynamoDB">
                            <a><img src={DynamoDBIcon} alt="DynamoDB" style={styles.Icon} /></a>
                        </Tooltip>
                        <Tooltip title="Lex">
                            <a><img src={LexIcon} alt="Lex" style={styles.Icon} /></a>
                        </Tooltip>
                        <Tooltip title="KnowledgeBase">
                            <a><img src={KnowledgeBaseIcon} alt="KnowledgeBase" style={styles.Icon} /></a>
                        </Tooltip>
                        <Tooltip title="OpenSearch">
                            <a><img src={OpenSearchIcon} alt="OpenSearch" style={styles.Icon} /></a>
                        </Tooltip>
                    </div>
                </Container>
            </Card>
            <h2 style={{ marginTop: '25px', textAlign: 'center' }}>ARCHITECTURE</h2>
            <Grid container spacing={2}>
                <Grid xs={3} ></Grid>
                <Grid xs={3} style={{ cursor: 'pointer', padding: '20px' }} onClick={() => openModal1()}>
                    <Card style={{ margin: '5px' }}>
                        <CardMedia sx={{ height: 150 }} image={require('../images/genaiprocessflow.jpg')} />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Process Flow
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={3} style={{ cursor: 'pointer', padding: '20px' }} onClick={() => openModal2()}>
                    <Card style={{ margin: '5px' }}>
                        <CardMedia sx={{ height: 150 }} image={require('../images/genaiarchitecture.png')} />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Target Architecture
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={3} ></Grid>
            </Grid>
            <Dialog
                fullScreen
                open={showModal1}
                onClose={closeModal1}
                PaperProps={{
                    style: {
                        width: '90%',
                        maxWidth: '100%',alignItems:"center"
                    },
                }}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Process Flow
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={closeModal1}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box
                    component="img"
                    sx={{ height: '90vh', width: 'fit-content',py:2 }}
                    alt="logo"
                    src={require('../images/genaiprocessflow.jpg')}
                />
            </Dialog>

            <Dialog
                fullScreen
                open={showModal2}
                onClose={closeModal2}
                PaperProps={{
                    style: {
                        width: '90%',
                        maxWidth: '100%',alignItems:"center"
                    },
                }}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Target Architecture
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={closeModal2}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                
                <Box
                    component="img"
                    sx={{ height: '90vh', width: 'fit-content',py:2 }}
                    alt="logo"
                    src={require('../images/genaiarchitecture.png')}
                />
            </Dialog>
        </Box>
    );
}