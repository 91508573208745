import React, { useState, useEffect } from 'react';
import { exchange } from '../common/ApiUtils';
import { API_BASE_URL } from "../App";
import TableComponent from '../documents/TableComponent';

export default function UserFileUpload({ userRole }) {
    const [listLoading, setListLoading] = useState(false);
    const [docNames, setDocNames] = useState([]);
    const [cloudProvider, setCloudProvider] = useState('aws');
    const [cloudProviders, setCloudProviders] = useState([]);
    useEffect(() => {
        fetchConfigData("tenant_config"); // Replace 'tenant_config' with your desired config type
        fetchData();
    }, [cloudProvider]);

    const fetchConfigData = async (configType) => {
        try {
          const response = await exchange(
            `${API_BASE_URL}/config/${configType}`,
            "GET"
          );
          const doubleEncodedData = response.body;
          const data = JSON.parse(JSON.parse(doubleEncodedData));
          const supportedClouds = data.supported_clouds || [];
          setCloudProviders(supportedClouds);
        } catch (error) {
          console.error("Error fetching config data:", error);
        }
      };

    const fetchData = () => {
        setListLoading(true);
        let url;

        if (cloudProvider === 'aws') {
            url = `${API_BASE_URL}/admin/documents`;
        } else if (cloudProvider === 'azure') {
            url = `${API_BASE_URL}/admin/az/documents`;
        } else {
            console.error('Unknown cloud provider:', cloudProvider);
            setListLoading(false);
            return;
        }

        exchange(url, 'GET')
            .then((data) => {
                setDocNames(data);
                setListLoading(false);
            })
            .catch(error => {
                setDocNames([]);
                setListLoading(false);
                console.error('Error fetching data:', error);
            });
    };

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'name', headerName: 'Name', flex: 2 },
        { field: 'size', headerName: 'Size', flex: 1 },
        { field: 'created_time', headerName: 'Created Time', flex: 1 },
        { field: 'modified_time', headerName: 'Modified Time', flex: 1 },
    ];

    return (
        <TableComponent
            columns={columns}
            docNames={docNames}
            listLoading={listLoading}
            alert={{ open: false, severity: 'info', message: '' }} // Mock alert prop for now
            userRole={userRole}
            cloudProvider={cloudProvider}
            setCloudProvider={setCloudProvider}
            cloudProviders={cloudProviders}
        />
    );
}
