import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoginBgImage from '../images/illustration.jpg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import jwtDecode from 'jwt-decode';
import {
    API_BASE_URL
} from "../App";
import { exchange } from '../common/ApiUtils';
import { Backdrop, CircularProgress, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://ust.com/">
                UST
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
const defaultTheme = createTheme();

export default function SignIn() {
    const history = useHistory();
    const [signingIn, setSigningIn] = React.useState(false);

    const [emailError, setEmailError] = React.useState(false);
    const [pwdError, setPwdError] = React.useState(false);

    const [invalidLogin, setInvalidLogin] = React.useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = React.useState('');

    const handleEmailInputChange = () => {
        setInvalidLogin(false);
        setLoginErrorMessage('');
        setEmailError(false);
    };

    const Redirect=(exp)=>{
        const currentTime = Math.floor(Date.now() / 1000); //current time in seconds
        const timeUntilExpiration = exp - currentTime;
        console.log(timeUntilExpiration)
        setTimeout(() => {
            history.push("/");
            localStorage.removeItem('genai-app-jwt')
            localStorage.removeItem('genai-app-idtoken')
          }, timeUntilExpiration*1000);
    }

    const handlePwdInputChange = () => {
        setInvalidLogin(false);
        setLoginErrorMessage('');
        setPwdError(false);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setInvalidLogin(false);
        setLoginErrorMessage('');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setInvalidLogin(false);
        setLoginErrorMessage('');
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        const password = data.get('password');
        // Perform form validation
        if (!email || !password) {
            if(!email) {
                setEmailError(true);
            }
            if(!password) {
                setPwdError(true);
            }
            return;
        }
        setSigningIn(true);
        let body = {
            "username": email,
            "password": password
        }
        exchange(`${API_BASE_URL}/auth`, "POST", body)
            .then(data => {
                if (data && !data.error && data.data.access_token) {
                    localStorage.setItem('genai-app-jwt', data.data.access_token)
                    localStorage.setItem('genai-app-idtoken', data.data.id_token)
                    let isAdmin = false;
                    let isSuperAdmin = false;
                    let isDevGrpUser = false;
                    if (data.data.access_token) {
                        const decodedToken = jwtDecode(data.data.access_token, { complete: true });
                        const exp = decodedToken.exp;
                        Redirect(exp) //invoking the Redirect function
                        if (decodedToken && decodedToken['cognito:groups']) {
                            isAdmin = decodedToken['cognito:groups'].includes('admin');
                        }
                        if (decodedToken && decodedToken['cognito:groups']) {
                            isSuperAdmin = decodedToken['cognito:groups'].includes('tenant_admin');
                        }
                        if (decodedToken && decodedToken['cognito:groups']) {
                            isDevGrpUser = decodedToken['cognito:groups'].includes('dev');
                        }
                    }
                    if (isSuperAdmin)
                        history.push("/t/admin", {"devGrpUser": isDevGrpUser});
                    else if (isAdmin)
                        history.push("/d/admin", {"devGrpUser": isDevGrpUser});
                    else
                        history.push("/home", {"devGrpUser": isDevGrpUser});
                } else {
                    setInvalidLogin(true);
                    setLoginErrorMessage(data.message);
                    console.error(data.message);
                }
                setSigningIn(false);
            });

    }


    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={8}
                    sx={{
                        backgroundImage: `url(${LoginBgImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}
                />
                <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square sx={{ height: '100vh' }}>
                    <Box
                        sx={{
                            my: 22,
                            mx: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            component="img"
                            alt="logo"
                            src={require('../images/logo.png')}
                        />

                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={handleEmailInputChange}
                                // Add input validation
                                error={emailError} // Check if error and email is empty
                                helperText={emailError ? 'Email is required' : ''} // Show error message
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={handlePwdInputChange}
                                // Add input validation
                                error={pwdError} // Check if error and password is empty
                                helperText={pwdError ? 'Password is required' : ''} // Show error message
                            />
                            {/* <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            /> */}
                            {invalidLogin && (
                                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                                    {loginErrorMessage}
                                </Alert>
                            )}
                            <Button
                                type="submit" lo
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign In
                            </Button>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={signingIn}

                >
                    <CircularProgress color="inherit" />
                </Backdrop>

            </Grid>
        </ThemeProvider>
    );
}