// Adjusted code with updated messages based on content
import React, { useEffect, useRef, useState } from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Person2Icon from "@mui/icons-material/Person2";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { Tooltip } from "@mui/material";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ChatInput from "./ChatInput";
import ChatSessions from "./ChatSessions";
import ChatIcon from "@mui/icons-material/Chat";
import { exchange } from "../common/ApiUtils";
import jwtDecode from "jwt-decode";
import { green, pink } from "@mui/material/colors";
import { fontWeight } from "@mui/system";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { API_BASE_URL } from "../App";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";



export default function MyChatMessages(props) {
  const [chatMessages, setChatMessages] = useState([]);
  const [sessionid, setSessionId] = useState("");
  const [sessionname, setSessionName] = useState("New Chat");
  const [chatsessions, setChatSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatSessionSelectedIndex, setchatSessionSelectedIndex] = useState(-1);
  const [streamingData, setStreamingData] = useState("");
  const [streaming, setStreaming] = useState(false);
  const [waitingforllm, setWaitingForLlm] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [cloudId, setCloudId] = useState(localStorage.getItem("selectedCloudId"));
  const [selectedCloudId, setSelectedCloudId] = useState(localStorage.getItem("selectedCloudId"));
  const [llmId, setLlmId] = useState(localStorage.getItem('selectedLlmId') || null)
  const [selectedLlmId, setSelectedLlmId] = useState(localStorage.getItem('selectedLlmId') || null)
  const [llms, setLLMs] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [inputDisabled, setinputDisabled] = useState(false)
  const { appliedLlm, setAppliedLlm } = props
  const scrollRef = useRef(null);
  let jwtToken = localStorage.getItem("genai-app-idtoken");
  let decodedToken = jwtDecode(jwtToken, { complete: true });
  const [metadata, setMetadata] = useState([]);
  const username = decodedToken.email;
  const [llmloading, setllmloading] = useState(false)
  const [likedMessageIndex, setLikedMessageIndex] = useState(null);
  const [dislikedMessageIndex, setDislikedMessageIndex] = useState(null);
  const [chatloading, setChatloading] = useState(false)


  //   if(localStorage.getItem('selectedLlmId') === null || localStorage.getItem('llm_Response') === null){
  //     alert("please select a model");
  // }


  useEffect(() => {
    // Check if 'selectedLlmId' is present in localStorage
    const llmId = localStorage.getItem('selectedLlmId');

    if (llmId) {
      // If 'selectedLlmId' is found in localStorage, set it as the initial state
      setSelectedLlmId(llmId);
    }
  }, []);

  
  

  useEffect(() => {
    console.log("Getitem", localStorage.getItem("LlmName"))
    if (!localStorage.getItem("LlmName") || !localStorage.getItem("selectedLlmId") || !localStorage.getItem("llmid")) {
      localStorage.setItem("LlmName", "Anthropic Claude 3 Sonnet")
      localStorage.setItem("selectedLlmId", "anthropic.claude-3-sonnet-20240229-v1:0")
      localStorage.setItem("llmid", "anthropic.claude-3-sonnet-20240229-v1:0")
      localStorage.setItem("selectedCloudId","aws")
    }
  }, [])
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevChatMessages = usePrevious(chatMessages);

  useEffect(() => {
    mountFunction();
  }, []);


  useEffect(() => {
    if (scrollRef.current) {
      if (
        prevChatMessages &&
        chatMessages &&
        prevChatMessages.length != chatMessages.length
      )
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages]);

  async function mountFunction() {
    newChatSession();
    refreshChatSessionComponent();
    getLLMs();
  }

  const getLLMName = (llmid) => {
    for (let provider in llms) {
      console.log("Provider: ", provider)
      for (let llm of llms[provider]) {
        if (llm.llmid === llmid) {
          return llm.llmname;
        }
      }
    }
    return null;
  };

  const updateChatSession = (message) => {
    console.log(JSON.stringify(message));
    exchange(
      "/api/chathistory/" + username + "/" + sessionid + "/chats",
      "POST",
      { messages: message }
    )
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const refreshChatSessionComponent = () => {
    setChatloading(true)
    exchange(
      "/api/chathistory/" +
      username +
      "?searchType=RAG&fields=sessionname,sessionid,updatedon",
      "GET",
      null
    )
      .then((data) => {
        let sessions = data.sort((a, b) => b.updatedon - a.updatedon);
        setChatSessions(sessions);


        setChatloading(false)

        console.log(data);
      })
      .catch((error) => console.log(error));
  };

  //Time && date 
  const Date_time = () => {
    const now = new Date();
    const localeString = now.toLocaleString([], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
    const [date, time] = localeString.split(', ');
    return { date, time };

  }



  const onChatInputSendButtonClick = (input) => {
    setinputDisabled(true);
    setWaitingForLlm(true);
    const dateTime = Date_time();
    const dateTimeString = dateTime.date + "  " + dateTime.time;
    //invoking the date
    let message = { role: "user", content: input, time: dateTimeString};
    updateChatSession(message);
    // setMetadata([]);
    setChatMessages((messages) => [...messages, message]);

    let url = `/api/invoke/?cloud=${cloudId}&llm=${localStorage.getItem("selectedLlmId")}&prompt=${input}`;
    if (sessionname === "New Chat") {
      url =
        url +
        "&setSubject=True&sessionid=" +
        encodeURIComponent(sessionid) +
        "&userid=" +
        encodeURIComponent(username) +
        "&searchType=RAG";
    }

    // fetchData(input);

    exchange(url, "GET", null)
      .then((data) => {
        const dateTime = Date_time();
        const dateTimeString = dateTime.date + "  " + dateTime.time;
        message = {
          role: "assistant",
          content: data.result,
          citations: data.metadata,
          cloud: cloudId,
          llm: llmId,
          time: dateTimeString
        };
        setChatMessages((messages) => [...messages, message]);
        updateChatSession(message);
        // setMetadata(data.metadata);
        setinputDisabled(false);
        setWaitingForLlm(false);
        if (sessionname === "New Chat") {
          setSessionName(data.subject);
          console.log("data.subject", data.subject)
          refreshChatSessionComponent();
        }
        console.log(data);
      })
      .catch((error) => {
        console.error(error.message);
        setinputDisabled(false);
      });
    input = "";
  };


  const getLLMs = () => {
    const llmvalue = JSON.parse(localStorage.getItem("llm_Response"))
    setllmloading(true);
    console.log("llmvalue", llmvalue)
    if (!llmvalue && localStorage.getItem("genai-app-jwt")) {
      exchange(`${API_BASE_URL}/llms/`, "GET")
        .then((response) => {
          if (response != null) {
            localStorage.setItem("llm_Response", JSON.stringify(response))
            setLLMs(response);
            setCloudId(localStorage.getItem("selectedCloudId"));
            setSelectedCloudId(localStorage.getItem("selectedCloudId"));
            const firstLlm = response[0];
            if (firstLlm) {
              setSelectedLlmId(firstLlm.llmid);
              setLlmId(firstLlm.llmid);
            }
          }
          setllmloading(false);
        })
        .catch((error) => {
          console.error(error.message);
          setinputDisabled(false);
          setllmloading(false);
        });
    }
    else {
      setLLMs(llmvalue);
      setCloudId(localStorage.getItem("selectedCloudId"));
      setSelectedCloudId(localStorage.getItem("selectedCloudId"));
      const firstLlm = llmvalue[0];
      if (firstLlm) {
        setSelectedLlmId(firstLlm.llmid);
        setLlmId(firstLlm.llmid);
      }
      setllmloading(false);
    }
  }


  // useEffect(() => {
  //   let firstLlm;

  //   let firstLlmId = firstLlm ? firstLlm.llmid : null;

  //   if (!llmId) {
  //     setLlmId(firstLlmId);
  //     setSelectedLlmId(firstLlmId);
  //   } else {
  //     let selectedLlm;
  //     for (let provider in llms) {
  //       selectedLlm = llms[provider].find((e) => e.llmid == llmId);
  //       if (selectedLlm) break;
  //     }
  //     if (selectedLlm && selectedLlm.cloudid == selectedCloudId) {
  //       setSelectedLlmId(llmId);
  //     } else {
  //       setSelectedLlmId(firstLlmId);
  //     }
  //   }
  // }, [selectedCloudId, llmId, llms]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    setSelectedCloudId(cloudId);
    setSelectedLlmId(llmId);
  };
  // useEffect(() => {
  //   console.log("Current selectedLlmId value:", selectedLlmId);
  //   if (!selectedLlmId) {
  //     toggleDrawer(); 
  //   }
  // }, []);
  // useEffect(() => {
  //    localStorage.setItem("selectedLlmId","anthropic.claude-3-sonnet-20240229-v1:0")
  //    localStorage.setItem("LlmName","Anthropic Claude 3 Sonnet")
  //    localStorage.setItem("llmid","anthropic.claude-3-sonnet-20240229-v1:0")
  //   }, []);

  const handleCloudChange = (event) => {
    const cloud_id = event.target.value;
    setSelectedCloudId(cloud_id);
    localStorage.setItem("selectedCloudId", cloud_id);
    setSelectedLlmId("")
    localStorage.setItem("selectedLlmId","")
    localStorage.setItem('LlmName',"")
    localStorage.setItem('llmid',"")
  };

  const handleModelChange = (event) => {
    const llmId = event.target.value;
    setSelectedLlmId(llmId);
    localStorage.setItem('selectedLlmId', llmId);
  };

  const handleProviderClick = (event, provider) => {
    if (provider !== null) {
      setSelectedProvider(provider);
    }
  };

  const getCostIcon = (costCategory) => {
    const iconStyle = {
      height: "12px",
      width: "12px",
    };

    const generateIcons = (count, color, title) => {
      const icons = [];
      for (let i = 0; i < count; i++) {
        icons.push(
          <AttachMoneyIcon
            key={i}
            style={{
              ...iconStyle,
              color: color,
              marginLeft: i === 0 ? 0 : "-6px",
              zIndex: count - i,
            }}
          />
        );
      }
      return (
        <Tooltip title={title} placement="top">
          <Box display="flex" alignItems="center">
            {icons}
          </Box>
        </Tooltip>
      );
    };

    switch (costCategory) {
      case "Low":
        return (
          <Tooltip title="Cost : Low" placement="top">
            <AttachMoneyIcon style={{ ...iconStyle, color: "green" }} />
          </Tooltip>
        );
      case "Medium":
        return generateIcons(2, "green", "Cost : Medium");
      case "High":
        return generateIcons(3, "green", "Cost : High");
      default:
        return null;
    }
  };

  const applyLlmSelection = () => {
    setCloudId(selectedCloudId);
    setLlmId(localStorage.getItem("selectedLlmId"));
    setDrawerOpen(false);
    // setAppliedLlm(llms.find(llm => llm.llmid == selectedLlmId).llmname);
    const values = Object.values(llms);
    values.forEach(value => {
      console.log("value", value);
      const llmData = value.find(llm => llm.llmid == selectedLlmId);
      if (llmData) {
        localStorage.setItem('llmid', selectedLlmId)
        localStorage.setItem('LlmName', llmData.llmname)
        setAppliedLlm(llmData.llmname);
      }
    });


  };

  const onChatSessionListItemClick = (item, index) => {
    setchatSessionSelectedIndex(index);
    // setMetadata([]);
    setChatMessages([]);
    setSessionId(item.sessionid + "");
    setSessionName(item.sessionname);
    setLoading(true);
    exchange("/api/chathistory/" + username + "/" + item.sessionid, "GET")
      .then((data) => {
        setChatMessages((messages) => [...messages, ...data.messages]);
        setLoading(false);
        let listBottom = document.getElementById("listBottom");
        listBottom.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
        console.log("data", data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const newChatSession = () => {
    setChatMessages([]);
    // setMetadata([]);
    setLoading(true);
    setchatSessionSelectedIndex(-1);
    setStreamingData("");
    exchange("/api/uuid", "GET")
      .then((data) => {
        setSessionId(data.sessionid);
        setSessionName("New Chat");
        setLoading(false);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const updateFeedback = (index, action) => {
    let message = { feedback: action };
    console.log(JSON.stringify(message));
    exchange(
      "/api/chathistory/" + username + "/" + sessionid + "/chats/" + index,
      "POST",
      { messages: message }
    )
      .then((data) => {
        console.log(data);
        if (data.status === 200) {
          let messages = [...chatMessages];
          let msgToModify = { ...messages[index] };
          msgToModify.feedback = action;
          messages[index] = msgToModify;
          setChatMessages(messages);
        } else {
          console.error("Failed to update feedback!!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filterUniqueCitations = (citations) => {
    const cits = new Set();
    return citations.filter((citation) => {
      let uri;
      uri = citation.metadata.location
        ? citation.metadata.location.s3Location.uri
        : citation.metadata.url;
      if (cits.has(uri)) {
        return false;
      } else {
        cits.add(uri);
        return true;
      }
    });
  };

  const filterLLMsByCloudProvider = (cloudProvider) => {
    if (cloudProvider in llms) {
      return llms[cloudProvider];
    }
    return [];
  };

  const tableStyles = `
    table {
      width: 100%;
      border-collapse: collapse;
    }
    th, td {
      border: 1px solid black;
      padding: 8px;
      text-align: left;
    }
  `;
  var displayMessages = "";
  displayMessages = (
    <Grid container height="90vh" style={{ overflow: "clip" }}>
      <Grid xs={3}>
        <Button
          variant="outlined"
          style={{ width: "90%", margin: "15px" }}
          endIcon={<ChatIcon />}
          onClick={newChatSession}
        >
          New Chat
        </Button>
        <div style={{ height: "70%", maxHeight: "80%", overflow: "auto" }}>

          <ChatSessions
            selectedIndex={chatSessionSelectedIndex}
            data={chatsessions}
            onChatSessionClick={onChatSessionListItemClick}
            loading={chatloading}
          />

        </div>
      </Grid>
      <Grid xs={9}>
        <Paper
          style={{
            height: "100%",
            maxHeight: "100%",
            overflow: "auto",
            margin: "1px 0px 0px 0px",
            backgroundColor: "#d6d6d6",
          }}
          elevation={3}
        >
          <Box height="75vh">
            <List
              style={{
                height: "100%",
                maxHeight: "100%",
                overflow: "auto",
                paddingLeft: "80px",
                paddingRight: "80px",
              }}
            >
              {chatMessages.map((chat, index, messages) => (
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <React.Fragment>
                        {/* Avatar and user/genAI indication */}
                        <Stack direction="row" spacing={2}>
                          {chat.role === "user" ? (
                            <Avatar
                              sx={{
                                width: 24,
                                height: 24,
                                bgcolor: green[500],
                              }}
                            >
                              <Person2Icon />
                            </Avatar>
                          ) : (
                            <Avatar
                              sx={{ width: 24, height: 24, bgcolor: pink[500] }}
                            >
                              <SmartToyIcon />
                            </Avatar>
                          )}
                          {chat.role === "user" ? (
                            <span style={{ fontWeight: "bold" }}>You</span>
                          ) : (
                            <span style={{ fontWeight: "bold" }}>
                              GenAI{" "}
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {chat.cloud || chat.llm ? (
                                  `(Cloud: ${chat.cloud.toUpperCase()}, Model: `
                                ) : null}
                                {chat.cloud && chat.llm ? getLLMName(chat.llm, chat.cloud) || <CircularProgress size={9} /> : null}
                                {chat.cloud || chat.llm ? `)` : ""}
                              </Typography>{" "}
                            </span>
                          )}
                        </Stack>

                        {/* Chat content */}
                        <Stack direction="row" spacing={2}>
                          <div style={{ margin: "10px", marginBottom: "0px" }}>
                            &nbsp;
                          </div>
                          <Paper
                            elevation={2}
                            sx={{
                              backgroundColor:
                                chat.role === "user" ? "lightblue" : "white",
                              padding: "5px",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              marginRight: "70px",
                            }}
                          >
                            <div>
                              <style>{tableStyles}</style>
                              {chat.llm && chat.llm.startsWith('mistral') ? (
                                <div style={{ overflowWrap: 'break-word', whitespace: 'pre-wrap', wordBreak: 'keep-all', maxWidth: '100%' }}>
                                  {chat.content}
                                </div>
                              ) : (
                                <div >
                                  <Markdown remarkPlugins={[remarkGfm]}>
                                    {chat.content}

                                  </Markdown>
                                </div>
                              )}
                            </div>
                            <pre style={{ textAlign: 'right', color: 'gray', fontSize: '0.7em', marginTop: '5px' }}>
                              {chat.time}
                            </pre>
                          </Paper>
                        </Stack>

                        {/* Feedback icons */}
                        {chat.role === "user" ? null : (
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ mt: 1, ml: 1 }}
                          >
                            <div
                              style={{ margin: "10px", marginBottom: "0px" }}
                            >
                              &nbsp;
                            </div>
                            <ThumbUpIcon
                              style={{
                                color:
                                  chat.feedback === "liked"
                                    ? "blue"
                                    : "grey",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                updateFeedback(
                                  index,
                                  chat.feedback === "liked" ? null : "liked"
                                )
                              }
                            />
                            &nbsp;&nbsp;
                            <ThumbDownIcon
                              style={{
                                color:
                                  chat.feedback === "disliked"
                                    ? "darkred"
                                    : "grey",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                updateFeedback(
                                  index,
                                  chat.feedback === "disliked"
                                    ? null
                                    : "disliked"
                                )
                              }
                            />
                          </Stack>
                        )}

                        {/* Citations */}
                        {chat.citations && chat.citations.length > 0 && (
                          <Stack direction="row" spacing={1}>
                            {chat.citations.length > 0 ? (
                              <div style={{ marginLeft: "35px" }}></div>
                            ) : (
                              ""
                            )}
                            {filterUniqueCitations(chat.citations).slice(0, 3).map(
                              (citation, citationIndex) => (
                                <div key={citationIndex}>
                                  <Chip
                                    key={citationIndex}
                                    color="success"
                                    variant="outlined"
                                    icon={<PictureAsPdfOutlinedIcon />}
                                    label={
                                      <div>
                                        {citation.metadata.score && (
                                          <div>
                                            {citation.metadata.location.s3Location.uri
                                              .split(/[// ]+/)
                                              .pop()}{" "}
                                            |{" "}
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                color: "green",
                                              }}
                                            >
                                              Score:&nbsp;
                                            </span>
                                            {Math.round(
                                              citation.metadata.score * 100
                                            )}
                                            %
                                          </div>
                                        )}
                                        {!citation.metadata.score && (
                                          <div>
                                            {citation.metadata.url
                                              .split(/[// ]+/)
                                              .pop()}{" "}
                                          </div>
                                        )}
                                      </div>
                                    }
                                  />
                                </div>
                              )
                            )}
                          </Stack>
                        )}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
              {waitingforllm == true ? (
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Stack direction="row" spacing={2}>
                          <Avatar
                            sx={{ width: 24, height: 24, bgcolor: pink[500] }}
                          >
                            <SmartToyIcon />
                          </Avatar>
                          <span style={{ fontWeight: "bold" }}>GenAI</span>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                          <div style={{ margin: "10px", marginBottom: "0px" }}>
                            &nbsp;
                          </div>
                          <Paper
                            elevation={2}
                            sx={{
                              backgroundColor: "white",
                              padding: "15px",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              marginRight: "70px",
                            }}
                          >
                            <>
                              <CircularProgress color="secondary" />
                            </>
                          </Paper>
                        </Stack>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
              {streaming == true ? (
                <ListItem alignItems="flex-start">
                  <ListItemIcon>
                    <SmartToyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Markdown>{streamingData}</Markdown>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
              <div id="listBottom" ref={scrollRef}></div>
            </List>

            <ChatInput
              onSendButtonClick={onChatInputSendButtonClick}
              selectedLlm={appliedLlm}
              toggleDrawer={toggleDrawer}
              disabled={loading}
              inputDisabled={inputDisabled}
              setinputDisabled={setinputDisabled}
              cloud={cloudId}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
  return (
    <div id="ByMyMessages">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {displayMessages}
      <Drawer
        open={drawerOpen}
        onClose={toggleDrawer}
        value={selectedLlmId}
        anchor="right"
        display="flex"
        sx={{ ".MuiDrawer-paper": { borderRadius: "10px 0 0 10px" } }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px={2}
          py={1}
          sx={{ width: 300, paddingRight: 1 }}
        >
          <Typography sx={{ fontSize: "1rem", fontWeight: "500" }}>
            Preferences
          </Typography>
          <IconButton color="primary" onClick={toggleDrawer} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        {llmloading ? (
          <Box p={2} sx={{ width: 300 }}>
            <Skeleton variant="text" width={120} height={20} />
            <Skeleton variant="rectangular" height={40} sx={{ my: 1 }} />
            <Skeleton variant="text" width={160} height={20} />
            <Skeleton variant="rectangular" height={400} />
          </Box>
        ) : (
          <>
            <Box p={2} sx={{ width: 300 }}>
              <Typography
                sx={{
                  fontSize: "0.6875rem",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  marginBottom: "8px",
                }}
              >
                Cloud
              </Typography>
              <ToggleButtonGroup
                color="primary"
                value={selectedCloudId}
                onChange={handleCloudChange}
                exclusive
                fullWidth
                aria-label="Cloud"
              >
                <ToggleButton value="aws">AWS</ToggleButton>
                <ToggleButton value="azure">Azure</ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Divider />
            <Box p={2} sx={{ width: 200 }}>
              <Typography
                sx={{
                  fontSize: "0.6875rem",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  textAlign: "left", // Center alignment
                }}
              >
                Providers & Models
              </Typography>
            </Box>
            <Divider />
            <Box
              display="flex"
              flexDirection="column"
              sx={{ flexGrow: 1, width: 300, overflowY: "auto" }}
            >
              <Divider />
              <Box p={2} sx={{ width: 300 }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    name="llm"
                    // checked={selectedLlmId}
                    value={selectedLlmId || localStorage.getItem("selectedLlmId")}
                    onChange={handleModelChange}
                    aria-label="LLM"
                  >
                    {Object.keys(llms)
                      .filter((provider) =>
                        llms[provider].some(
                          (llm) => llm.cloudid === selectedCloudId
                        )
                      )
                      .map((provider) => (
                        <React.Fragment key={provider}>
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "0.8125rem",
                              marginTop: "16px",
                              textTransform: "uppercase",
                            }}
                          >
                            {provider.toUpperCase()}
                          </Typography>
                          <Divider />
                          {llms[provider]
                            .filter((llm) => llm.cloudid === selectedCloudId)
                            .map((llm) => (
                              <FormControlLabel
                                key={llm.llmid}
                                value={llm.llmid}
                                control={<Radio />}
                                label={
                                  <Box display="flex" alignItems="center">
                                    <Typography
                                      sx={{
                                        fontWeight: "400",
                                        fontSize: "0.8125rem",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {llm.llmname}
                                    </Typography>
                                    <Box ml={1} display="flex" alignItems="center">
                                      {getCostIcon(llm.costcategory)}
                                    </Box>
                                  </Box>
                                }
                              />
                            ))}
                        </React.Fragment>
                      ))}
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>

            <Divider />
            <Box
              display="flex"
              justifyContent="center"
              px={2}
              py={1}
              sx={{ width: 300 }}
            >
              <Button
                color="primary"
                startIcon={<CheckIcon />}
                onClick={()=>{applyLlmSelection()}}
                disabled={!selectedLlmId}
                
              >
                Apply
              </Button>
            </Box>
          </>
        )}

      </Drawer>
    </div>
  );
}
