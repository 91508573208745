import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import { LinearProgress, Typography } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function ChatInput(props) {
    const {selectedPage, setPageName}=props;
    // console.log("selectedPage",selectedPage)
    const [input, setInput] = useState(props?.value ?? '');
    const [llmResponse, setLlmResponse] = useState(() => {
        const selectedLlmId = props.selectedLlmId;
        if (selectedLlmId) {
            return selectedLlmId;
        } else {
            const llmValue = localStorage.getItem('llmId');
            return llmValue || '';
        }
    });

    const handleSendButtonClick = () => {
        if(!llmvaluecheck()){
            props.onSendButtonClick(input);
            setInput("");
            setLlmResponse("");
        }
       
    };
    const llmvaluecheck=()=>{
        const llmIdExist  = localStorage.getItem("llmid")
        const selectedLlm =localStorage.getItem("selectedLlmId")
        const llmName =localStorage.getItem("LlmName")
        if (!llmIdExist||!selectedLlm||!llmName){
            toast.error("Please select a Model to proceed ");
            return true; 
        }
        return false

    }

    useEffect(() => {
        const handleStorageChange = () => {
            const llmValue = localStorage.getItem('llmId');
            setLlmResponse(llmValue || '');
        };

        // Add event listener to handle changes to local storage
        window.addEventListener('storage', handleStorageChange);

        // Remove event listener when component unmounts
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []); // Empty dependency array to run the effect only once during component mount

    return (
        <div style={{ marginTop: '10px' }}>
            <Paper
                component="form" fullWidth variant="outlined"
                sx={{ ml: "130px", mr: "95px", mt: "0px", mb: "50px", p: '2px 4px', display: 'flex', alignItems: 'center',cursor:'pointer' }}
               onSubmit={(e) => {
                    e.preventDefault();
                    if(input.length > 0){
                        handleSendButtonClick();
                    }
               }}   
            >
                
                <InputBase
                    sx={{ ml: 1, flex: 1 ,width: 'auto',overflow: 'hidden', whiteSpace: 'nowrap'}}
                    placeholder="Ask me something"
                    inputProps={{ 'aria-label': 'Ask me something' }}
                    value={input}
                    onChange={e => setInput(e.target.value)}
                    disabled={props.inputDisabled}
                    multiline 
                    maxRows={3}
                    
                    // disabled={props.loading}
                    
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                    color="primary"
                    sx={{ p: '10px' }}
                    aria-label="send"
                    onClick={handleSendButtonClick}
                    disabled={props.inputDisabled||input.length==0}
                >
                    <SendIcon />
                    
                </IconButton>
                
                {selectedPage !== 'Agent' && (
                <IconButton
                    color="primary"
                    onClick={() => { props.toggleDrawer(); setInput(""); }}
                    // onClick={()=>{llmvaluecheck()}}
                    disabled={props.disabled}
                    sx={{ marginLeft: '-6px' }}
                    aria-label="preferences"
                >
                    <SettingsIcon />
                </IconButton>
                 )}
            </Paper>
            {selectedPage!=='Agent' &&(
                <Typography variant="body2" color="text.secondary">
                {localStorage.getItem("LlmName") ? (
                    <span style={{ fontWeight: "bold" }}>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ ml: "130px", mr: "95px", mt: "20px", mb: "0px", p: '2px 4px', display: 'flex', alignItems: 'center',marginTop:'-46px' }}
                        >
                            Cloud: {props.cloud ? props.cloud.toUpperCase() : ''} {",  "}
                            Model: {localStorage.getItem('LlmName')}
                        
                        </Typography>
                    </span>
                ) : (
                    <span style={{ fontWeight: "bold" }}></span>
                )}
            </Typography>

            )}
            {props.loading && <LinearProgress />}
            <ToastContainer position="bottom-right" />

        </div>

            
        
    );
}
