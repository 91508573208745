// Adjusted code with updated messages based on content

import React, { useEffect, useRef, useState } from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Person2Icon from "@mui/icons-material/Person2";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { Tooltip } from "@mui/material";
// import { Container } from "react-dom";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ChatInput from "./ChatInput";
import ChatSessions from "./ChatSessions";
import ChatIcon from "@mui/icons-material/Chat";
import { exchange } from "../common/ApiUtils";
import jwtDecode from "jwt-decode";
import { green, pink } from "@mui/material/colors";
import { fontWeight } from "@mui/system";
// import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
// import ArticleIcon from '@mui/icons-material/Article';
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { API_BASE_URL } from "../App";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { left } from "@popperjs/core";

export default function MyChatMessages(props) {
  const [chatMessages, setChatMessages] = useState([]);
  const [sessionid, setSessionId] = useState("");
  const [sessionname, setSessionName] = useState("New Chat");
  const [chatsessions, setChatSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatSessionSelectedIndex, setchatSessionSelectedIndex] = useState(-1);
  const [streamingData, setStreamingData] = useState("");
  const [streaming, setStreaming] = useState(false);
  const [waitingforllm, setWaitingForLlm] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [cloudId, setCloudId] = useState("aws");
  const [selectedCloudId, setSelectedCloudId] = useState(null);
  const [llmId, setLlmId] = useState(
    localStorage.getItem("selectedLlmId") || null
  );
  const [selectedLlmId, setSelectedLlmId] = useState(
    localStorage.getItem("selectedLlmId") || null
  );
  const [llms, setLLMs] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [inputDisabled, setinputDisabled] = useState(false);
  const { appliedLlm, setAppliedLlm } = props;
  const scrollRef = useRef(null);
  let jwtToken = localStorage.getItem("genai-app-idtoken");
  let decodedToken = jwtDecode(jwtToken, { complete: true });
  const [metadata, setMetadata] = useState([]);
  const username = decodedToken.email;
  const [llmloading, setllmloading] = useState(false);
  const [likedMessageIndex, setLikedMessageIndex] = useState(null);
  const [dislikedMessageIndex, setDislikedMessageIndex] = useState(null);
  const [chatloading, setChatloading] = useState(false);
  const [syncAgentResponse, setSyncAgentResponse] = React.useState({});
  const [syncAgentMetadataResponse, setSyncAgentMetadataResponse] =
    React.useState({});
  const [syncingFailed, setASyncResponseFailed] = React.useState(null);
  const [syncing, setASyncResponse] = React.useState(null);
  const [syncingSucceeded, setASyncResponseSucceeded] = React.useState(null);
  useEffect(() => {
    // Check if 'selectedLlmId' is present in localStorage
    const llmId = localStorage.getItem("selectedLlmId");

    if (llmId) {
      // If 'selectedLlmId' is found in localStorage, set it as the initial state
      setSelectedLlmId(llmId);
    }
  }, []);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevChatMessages = usePrevious(chatMessages);

  useEffect(() => {
    mountFunction();
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      if (
        prevChatMessages &&
        chatMessages &&
        prevChatMessages.length != chatMessages.length
      )
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages]);

  async function mountFunction() {
    newChatSession();
    refreshChatSessionComponent();
    // getLLMs();
  }

  const updateChatSession = (message) => {
    console.log(JSON.stringify(message));
    exchange(
      "/api/chathistory/" + username + "/" + sessionid + "/chats",
      "POST",
      { messages: message }
    )
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const refreshChatSessionComponent = () => {
    setChatloading(true);

    exchange(
      "/api/chathistory/" +
        username +
        "?searchType=Agent&fields=sessionname,sessionid,updatedon",
      "GET",
      null
    )
      .then((data) => {
        let sessions = data.sort((a, b) => b.updatedon - a.updatedon);
        setChatSessions(sessions);
        setChatloading(false);
        console.log(data);
      })
      .catch((error) => console.log(error));
  };
  //date&Time
  const Date_time = () => {
    const now = new Date();
    const localeString = now.toLocaleString([], {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const [date, time] = localeString.split(", ");
    return { date, time };
  };

  useEffect(() => {
    let citations = [];
    console.log("setAgentMetadataResponse", syncAgentMetadataResponse);
    const dateTime = Date_time();
    const dateTimeString = dateTime.date + "  " + dateTime.time;
    if (Array.isArray(syncAgentMetadataResponse)) {
      console.log("Processing metadata response");

      syncAgentMetadataResponse.forEach((metadataItem) => {
        if (typeof metadataItem === "object" && metadataItem !== null) {
          Object.entries(metadataItem).forEach(([key, value]) => {
            console.log("Processing item:", key, value);
            let citation = {
              pdf: "",
              ppt: "",
            };

            if (key === "location" && value && value.s3Location) {
              let fileUri = value.s3Location.uri;
              console.log("File URI:", fileUri);

              if (fileUri.includes(".pdf")) {
                citation.pdf = fileUri;
              } else if (fileUri.includes(".pptx")) {
                citation.ppt = fileUri;
              }

              if (citation.pdf || citation.ppt) {
                citations.push(citation);
              }
            }
          });
        }
      });
    }

    // Only proceed if we have a syncAgentResponse
    if (syncAgentResponse && syncAgentResponse.result) {
      const message = {
        role: "assistant",
        content: syncAgentResponse.result,
        citations: citations,
        cloud: cloudId, // Make sure cloudId is defined or passed as a prop
        llm: llmId, // Make sure llmId is defined or passed as a prop
        time: dateTimeString,
      };
      setChatMessages((messages) => [...messages, message]);
      updateChatSession(message); // Make sure updateChatSession is defined or passed as a prop
      setWaitingForLlm(false);

      if (sessionname === "New Chat") {
        refreshChatSessionComponent(); // Make sure this function is defined or passed as a prop
      }
    }
  }, [syncAgentMetadataResponse, syncAgentResponse]);

  const onChatInputSendButtonClick = (input) => {
    setWaitingForLlm(true);
    const dateTime = Date_time();
    const dateTimeString = dateTime.date + "  " + dateTime.time;
    let message = { role: "user", content: input, time: dateTimeString };
    updateChatSession(message);
    setChatMessages((messages) => [...messages, message]);
    let url = `/api/agent/sync/?prompt=${input}`;
    if (sessionname === "New Chat") {
      url = `${url}&setSubject=True&sessionid=${encodeURIComponent(
        sessionid
      )}&userid=${encodeURIComponent(username)}&searchType=Agent`;
    }

    exchange(url, "POST")
      .then((data) => {
        if (data.statusCode === 200) {
          initiateAgentAsyncResponseCheck(data.request_id);
        } else {
          setASyncResponseFailed(true);
        }
      })
      .catch((error) => {
        setASyncResponseFailed(true);
        console.error(error.message);
      });
  };

  const initiateAgentAsyncResponseCheck = (request_id) => {
    let attemptCount = 0;
    const maxRetries = 20;
    const intervalTime = 5000; // 5 milliseconds

    const getAgentASyncResponse = async () => {
      if (attemptCount < maxRetries) {
        setASyncResponse(true);
        attemptCount++;
        try {
          const response = await exchange(
            `${API_BASE_URL}/agent/sync/${request_id}`,
            "GET"
          );
          if (response.status === "IN-PROGRESS") {
            setASyncResponse(true);
            setTimeout(getAgentASyncResponse, intervalTime);
          } else {
            if (response.status === "COMPLETE") {
              console.log("response", response);
              setASyncResponseSucceeded(true);
              console.log("setSyncAgentResponse>>>>>>>>>>>1:", response.result);
              setSyncAgentResponse(response);

              // Handle metadata as an array
              if (Array.isArray(response.metadata)) {
                setSyncAgentMetadataResponse(response.metadata);
              } else if (response.metadata) {
                // If it's not an array but exists, wrap it in an array
                setSyncAgentMetadataResponse([response.metadata]);
              } else {
                // If there's no metadata, set an empty array
                setSyncAgentMetadataResponse([]);
              }
            } else {
              setASyncResponseFailed(true);
            }
            setASyncResponse(false);
          }
        } catch (error) {
          console.error("Error in sync status check:", error);
          if (attemptCount >= maxRetries) {
            setASyncResponseFailed(true);
            setASyncResponse(false);
          } else {
            setTimeout(getAgentASyncResponse, intervalTime);
          }
        }
      } else {
        setASyncResponse(false);
        setASyncResponseFailed(true);
      }
    };

    getAgentASyncResponse();
  };
  useEffect(() => {
    let firstLlm;

    let firstLlmId = firstLlm ? firstLlm.llmid : null;

    if (!llmId) {
      setLlmId(firstLlmId);
      setSelectedLlmId(firstLlmId);
    } else {
      let selectedLlm;
      for (let provider in llms) {
        selectedLlm = llms[provider].find((e) => e.llmid == llmId);
        if (selectedLlm) break;
      }
      if (selectedLlm && selectedLlm.cloudid == selectedCloudId) {
        setSelectedLlmId(llmId);
      } else {
        setSelectedLlmId(firstLlmId);
      }
    }
  }, [selectedCloudId, llmId, llms]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    setSelectedCloudId(cloudId);
    setSelectedLlmId(llmId);
  };

  const handleCloudChange = (event) => {
    setSelectedCloudId(event.target.value);
  };

  const handleModelChange = (event) => {
    const llmId = event.target.value;
    setSelectedLlmId(llmId);
    localStorage.setItem("selectedLlmId", llmId);
  };

  const handleProviderClick = (event, provider) => {
    if (provider !== null) {
      setSelectedProvider(provider);
    }
  };

  const getCostIcon = (costCategory) => {
    const iconStyle = {
      height: "12px",
      width: "12px",
    };

    const generateIcons = (count, color, title) => {
      const icons = [];
      for (let i = 0; i < count; i++) {
        icons.push(
          <AttachMoneyIcon
            key={i}
            style={{
              ...iconStyle,
              color: color,
              marginLeft: i === 0 ? 0 : "-6px",
              zIndex: count - i,
            }}
          />
        );
      }
      return (
        <Tooltip title={title} placement="top">
          <Box display="flex" alignItems="center">
            {icons}
          </Box>
        </Tooltip>
      );
    };

    switch (costCategory) {
      case "Low":
        return (
          <Tooltip title="Cost : Low" placement="top">
            <AttachMoneyIcon style={{ ...iconStyle, color: "green" }} />
          </Tooltip>
        );
      case "Medium":
        return generateIcons(2, "green", "Cost : Medium");
      case "High":
        return generateIcons(3, "green", "Cost : High");
      default:
        return null;
    }
  };

  const applyLlmSelection = () => {
    setCloudId(selectedCloudId);
    setLlmId(selectedLlmId);
    setDrawerOpen(false);
    // setAppliedLlm(llms.find(llm => llm.llmid == selectedLlmId).llmname);
    const values = Object.values(llms);
    values.forEach((value) => {
      console.log("value", value);
      const llmData = value.find((llm) => llm.llmid == selectedLlmId);
      if (llmData) {
        localStorage.setItem("llmid", selectedLlmId);
        localStorage.setItem("LlmName", llmData.llmname);
        setAppliedLlm(llmData.llmname);
      }
    });
  };

  const onChatSessionListItemClick = (item, index) => {
    setchatSessionSelectedIndex(index);
    // setMetadata([]);
    setChatMessages([]);
    setSessionId(item.sessionid + "");
    setSessionName(item.sessionname);
    setLoading(true);
    exchange("/api/chathistory/" + username + "/" + item.sessionid, "GET")
      .then((data) => {
        setChatMessages((messages) => [...messages, ...data.messages]);
        setLoading(false);
        let listBottom = document.getElementById("listBottom");
        listBottom.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
        console.log("data", data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const newChatSession = () => {
    setChatMessages([]);
    // setMetadata([]);
    setLoading(true);
    setchatSessionSelectedIndex(-1);
    setStreamingData("");
    exchange("/api/uuid", "GET")
      .then((data) => {
        setSessionId(data.sessionid);
        setSessionName("New Chat");
        setLoading(false);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const updateFeedback = (index, action) => {
    let message = { feedback: action };
    console.log(JSON.stringify(message));
    exchange(
      "/api/chathistory/" + username + "/" + sessionid + "/chats/" + index,
      "POST",
      { messages: message }
    )
      .then((data) => {
        console.log(data);
        if (data.status === 200) {
          let messages = [...chatMessages];
          let msgToModify = { ...messages[index] };
          msgToModify.feedback = action;
          messages[index] = msgToModify;
          setChatMessages(messages);
        } else {
          console.error("Failed to update feedback!!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadDocument = (s3url) => {
    let url = `${API_BASE_URL}/documents`;
    let reqstBdy = { fileurl: s3url };
    exchange(url, "POST", reqstBdy)
      .then((dataResp) => {
        fetch(dataResp)
          .then((response) => {
            if (response.status === 200) {
              return response.blob();
            } else {
              throw new Error("Failed to fetch file");
            }
          })
          .then((content) => {
            var url = window.URL.createObjectURL(content);
            var a = document.createElement("a");
            a.href = url;
            var filename = s3url.substring(s3url.lastIndexOf("/") + 1);
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
          })
          .catch((error) => {
            console.error("Error fetching file:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  //file icon .ppt or .pdf
  const getIconByFileType = (fileType) => {
    switch (fileType) {
      case "pdf":
        return <PictureAsPdfIcon />;
      case "pptx":
        return (
          <Box
            component="img"
            sx={{ height: "53px", width: "fit-content", py: 2 }}
            alt="logo"
            src={require("../images/ppt.png")}
          />
        );
      default:
        return <InsertDriveFileIcon />;
    }
  };

  // Function to get the label based on the file type
  // const getLabelByFileType = (fileType) => {
  //   switch (fileType) {
  //     case 'pdf':
  //       return 'Download PDF';
  //     case 'pptx':
  //       return 'Download PPT';
  //     default:
  //       return 'Download File';
  //   }
  // };

  const tableStyles = `
    table {
      width: 100%;
      border-collapse: collapse;
    }
    th, td {
      border: 1px solid black;
      padding: 8px;
      text-align: left;
    }
  `;
  var displayMessages = "";
  displayMessages = (
    <Grid container height="90vh" style={{ overflow: "clip" }}>
      <Grid xs={3}>
        <Button
          variant="outlined"
          style={{ width: "90%", margin: "15px" }}
          endIcon={<ChatIcon />}
          onClick={newChatSession}
        >
          New Chat
        </Button>
        <div style={{ height: "70%", maxHeight: "80%", overflow: "auto" }}>
          <ChatSessions
            selectedIndex={chatSessionSelectedIndex}
            data={chatsessions}
            onChatSessionClick={onChatSessionListItemClick}
            loading={chatloading}
          />
        </div>
      </Grid>
      <Grid xs={9}>
        <Paper
          style={{
            height: "100%",
            maxHeight: "100%",
            overflow: "auto",
            margin: "1px 0px 0px 0px",
            backgroundColor: "#d6d6d6",
          }}
          elevation={3}
        >
          <Box height="75vh">
            <List
              style={{
                height: "100%",
                maxHeight: "100%",
                overflow: "auto",
                paddingLeft: "80px",
                paddingRight: "80px",
              }}
            >
              {chatMessages.map((chat, index, messages) => (
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <React.Fragment>
                        {/* Avatar and user/genAI indication */}
                        <Stack direction="row" spacing={2}>
                          {chat.role === "user" ? (
                            <Avatar
                              sx={{
                                width: 24,
                                height: 24,
                                bgcolor: green[500],
                              }}
                            >
                              <Person2Icon />
                            </Avatar>
                          ) : (
                            <Avatar
                              sx={{ width: 24, height: 24, bgcolor: pink[500] }}
                            >
                              <SmartToyIcon />
                            </Avatar>
                          )}
                          {chat.role === "user" ? (
                            <span style={{ fontWeight: "bold" }}>You</span>
                          ) : (
                            <span style={{ fontWeight: "bold" }}>GenAI </span>
                          )}
                        </Stack>

                        {/* Chat content */}
                        <Stack direction="row" spacing={2}>
                          <div style={{ margin: "10px", marginBottom: "0px" }}>
                            &nbsp;
                          </div>
                          <Paper
                            elevation={2}
                            sx={{
                              backgroundColor:
                                chat.role === "user" ? "lightblue" : "white",
                              padding: "5px",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              marginRight: "70px",
                            }}
                          >
                            <div>
                              <style>{tableStyles}</style>
                              {chat.llm && chat.llm.startsWith("mistral") ? (
                                <div
                                  style={{
                                    overflowWrap: "break-word",
                                    whitespace: "pre-wrap",
                                    wordBreak: "keep-all",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {chat.content}
                                </div>
                              ) : (
                                <div>
                                  <Markdown remarkPlugins={[remarkGfm]}>
                                    {chat.content}
                                  </Markdown>
                                </div>
                              )}
                            </div>
                            <pre
                              style={{
                                textAlign: "right",
                                color: "gray",
                                fontSize: "0.7em",
                                marginTop: "5px",
                              }}
                            >
                              {chat.time}
                            </pre>
                          </Paper>
                        </Stack>

                        {/* Feedback icons */}
                        {chat.role === "user" ? null : (
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ mt: 1, ml: 1 }}
                          >
                            <div
                              style={{ margin: "10px", marginBottom: "0px" }}
                            >
                              &nbsp;
                            </div>
                            <ThumbUpIcon
                              style={{
                                color:
                                  chat.feedback === "liked" ? "blue" : "grey",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                updateFeedback(
                                  index,
                                  chat.feedback === "liked" ? null : "liked"
                                )
                              }
                            />
                            &nbsp;&nbsp;
                            <ThumbDownIcon
                              style={{
                                color:
                                  chat.feedback === "disliked"
                                    ? "darkred"
                                    : "grey",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                updateFeedback(
                                  index,
                                  chat.feedback === "disliked"
                                    ? null
                                    : "disliked"
                                )
                              }
                            />
                          </Stack>
                        )}
                        <Grid container marginLeft={"8px"}>
                          {chat.citations &&
                            chat.citations.length > 0 &&
                            chat.citations
                              .slice(0, 3)
                              .map((citation, citationIndex) => {
                                return (
                                  <>
                                    {citation.pdf && (
                                      <Grid item>
                                        <Chip
                                          icon={getIconByFileType("pdf")}
                                          label={"Download PDF"}
                                          deleteIcon={<DownloadRoundedIcon />}
                                          onDelete={() =>
                                            downloadDocument(citation.pdf)
                                          }
                                          sx={{
                                            borderRadius: "7px",
                                            cursor: "pointer",
                                            backgroundColor: "whitesmoke",
                                            display: "flex",
                                            alignItems: "center",
                                            width: "140px",
                                            marginLeft: "30px",
                                            "& .MuiChip-deleteIcon": {
                                              "&:hover": {
                                                color: "#1976d2",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    )}
                                    {citation.ppt && (
                                      <Grid item>
                                        <Chip
                                          icon={getIconByFileType("pptx")}
                                          label={"Download PPT"}
                                          deleteIcon={<DownloadRoundedIcon />}
                                          onDelete={() =>
                                            downloadDocument(citation.ppt)
                                          }
                                          sx={{
                                            borderRadius: "7px",
                                            cursor: "pointer",
                                            backgroundColor: "whitesmoke",
                                            display: "flex",
                                            alignItems: "center",
                                            width: "140px",
                                            marginLeft: "30px",
                                            "& .MuiChip-deleteIcon": {
                                              "&:hover": {
                                                color: "#1976d2",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    )}
                                  </>
                                );
                              })}
                        </Grid>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
              {waitingforllm == true ? (
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Stack direction="row" spacing={2}>
                          <Avatar
                            sx={{ width: 24, height: 24, bgcolor: pink[500] }}
                          >
                            <SmartToyIcon />
                          </Avatar>
                          <span style={{ fontWeight: "bold" }}>GenAI</span>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                          <div style={{ margin: "10px", marginBottom: "0px" }}>
                            &nbsp;
                          </div>
                          <Paper
                            elevation={2}
                            sx={{
                              backgroundColor: "white",
                              padding: "15px",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              marginRight: "70px",
                            }}
                          >
                            <>
                              <CircularProgress color="secondary" />
                            </>
                          </Paper>
                        </Stack>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
              {streaming == true ? (
                <ListItem alignItems="flex-start">
                  {/* <button onClick={downloadDocument()}> click</button> */}

                  <ListItemIcon>
                    <SmartToyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Markdown>{streamingData}</Markdown>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
              <div id="listBottom" ref={scrollRef}></div>
            </List>

            <ChatInput
              onSendButtonClick={onChatInputSendButtonClick}
              selectedLlm={appliedLlm}
              toggleDrawer={toggleDrawer}
              disabled={loading}
              selectedPage="Agent"
              inputDisabled={inputDisabled}
              setinputDisabled={setinputDisabled}
              cloud={cloudId}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
  return (
    <div id="ByMyMessages">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {displayMessages}
      <Drawer
        open={drawerOpen}
        onClose={toggleDrawer}
        value={selectedLlmId}
        anchor="right"
        display="flex"
        sx={{ ".MuiDrawer-paper": {} }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px={2}
          py={1}
          sx={{ width: 300, paddingRight: 1 }}
        >
          <Typography sx={{ fontSize: "1rem", fontWeight: "500" }}>
            Preferences
          </Typography>
          <IconButton color="primary" onClick={toggleDrawer} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        {llmloading ? (
          <Box p={2} sx={{ width: 300 }}>
            <Skeleton variant="text" width={120} height={20} />
            <Skeleton variant="rectangular" height={40} sx={{ my: 1 }} />
            <Skeleton variant="text" width={160} height={20} />
            <Skeleton variant="rectangular" height={400} />
          </Box>
        ) : (
          <>
            <Box p={2} sx={{ width: 300 }}>
              <Typography
                sx={{
                  fontSize: "0.6875rem",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  marginBottom: "8px",
                }}
              >
                Cloud
              </Typography>
              <ToggleButtonGroup
                color="primary"
                value={selectedCloudId}
                onChange={handleCloudChange}
                exclusive
                fullWidth
                aria-label="Cloud"
              >
                <ToggleButton value="aws">AWS</ToggleButton>
                <ToggleButton value="azure">Azure</ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Divider />
            <Box p={2} sx={{ width: 200 }}>
              <Typography
                sx={{
                  fontSize: "0.6875rem",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  textAlign: "left", // Center alignment
                }}
              >
                Providers & Models
              </Typography>
            </Box>
            <Divider />
            <Box
              display="flex"
              flexDirection="column"
              sx={{ flexGrow: 1, width: 300, overflowY: "auto" }}
            >
              <Divider />
              <Box p={2} sx={{ width: 300 }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    name="llm"
                    // checked={selectedLlmId}
                    value={selectedLlmId}
                    onChange={handleModelChange}
                    aria-label="LLM"
                  >
                    {Object.keys(llms)
                      .filter((provider) =>
                        llms[provider].some(
                          (llm) => llm.cloudid === selectedCloudId
                        )
                      )
                      .map((provider) => (
                        <React.Fragment key={provider}>
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "0.8125rem",
                              marginTop: "16px",
                              textTransform: "uppercase",
                            }}
                          >
                            {provider.toUpperCase()}
                          </Typography>
                          <Divider />
                          {llms[provider]
                            .filter((llm) => llm.cloudid === selectedCloudId)
                            .map((llm) => (
                              <FormControlLabel
                                key={llm.llmid}
                                value={llm.llmid}
                                control={<Radio />}
                                label={
                                  <Box display="flex" alignItems="center">
                                    <Typography
                                      sx={{
                                        fontWeight: "400",
                                        fontSize: "0.8125rem",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {llm.llmname}
                                    </Typography>
                                    <Box
                                      ml={1}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      {getCostIcon(llm.costcategory)}
                                    </Box>
                                  </Box>
                                }
                              />
                            ))}
                        </React.Fragment>
                      ))}
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>

            <Divider />
            <Box
              display="flex"
              justifyContent="center"
              px={2}
              py={1}
              sx={{ width: 300 }}
            >
              <Button
                color="primary"
                startIcon={<CheckIcon />}
                onClick={applyLlmSelection}
                disabled={!selectedLlmId}
              >
                Apply
              </Button>
            </Box>
          </>
        )}
      </Drawer>
    </div>
  );
}
