import React, { useState, useEffect } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Divider } from "@mui/material";
import moment from "moment";
import {Skeleton} from "@mui/material";
import { Typography } from "@mui/material";

export default function ChatSessions(props) {

  
  // useEffect(() => {
  //   if (!props.chatloading) {
  //     console.log("props.data",props.data)
  //     // setLoading(false);
  //   }
  // }, [props.data]);
    
  return (
   

    <List>
      {props.loading ? (
        <>
        <ListItem style={{ padding: "0px 15px 0px 25px" ,marginBottom:'3px'}}>
          <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: '100%' }} />
        </ListItem>
        <ListItem style={{ padding: "0px 15px 0px 25px" ,marginBottom:'3px'}}>
          <Skeleton variant="text" sx={{fontSize: '1.5rem', width: '100%' }} />
        </ListItem>
        <ListItem style={{ padding: "0px 15px 0px 25px" ,marginBottom:'3px'}}>
          <Skeleton variant="text" sx={{fontSize: '1.5rem', width: '100%' }} />
        </ListItem>
        <ListItem style={{ padding: "0px 15px 0px 25px", marginBottom:'3px'}}>
          <Skeleton variant="text" sx={{fontSize: '1.5rem', width: '100%' }} />
        </ListItem>
        <ListItem style={{ padding: "0px 15px 0px 25px" ,marginBottom:'3px'}}>
          <Skeleton variant="text" sx={{fontSize: '1.5rem', width: '100%' }} />
        </ListItem>
      </>
      ) : (
        props.data && props.data.length > 0 ? (
          props.data.map((session, index) => (
            <React.Fragment key={index}>
              <ListItem selected={index === props.selectedIndex} style={{ padding: "0px 15px 0px 25px" }}>
                <ListItemButton style={{ padding: "2px" }} onClick={() => props.onChatSessionClick(session, index)}>
                  <ListItemText
                    primary={session.sessionname}
                    secondary={moment(new Date(parseFloat(session.updatedon))).format("DD-MMM-YYYY")}
                    primaryTypographyProps={{ style: { width: '77%', display: 'inline-flex',marginTop:'3px' } }}
                    secondaryTypographyProps={{ style: { display: '-webkit-inline-box', fontSize: 'smaller' } }}
                  />
                </ListItemButton>
              </ListItem>
              <Divider component="li" />
            </React.Fragment>
          ))
        ) : (
          <Typography variant="body1" color="textSecondary" style={{ padding: '20px', textAlign: 'center' }}>
            No chats to display
          </Typography>
        )
      )}

    </List>
 
    
    
  );
  
}